import React, { useState } from 'react';
import axios from 'axios'
import ArrowRight from 'icons/ArrowRight';
import Symbol from 'components/Symbol';
import SubmitButton from 'components/SubmitButton'

type Props = {};

const ForgotPasswordForm: React.FC<Props> = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [userNotRegistered, setUserNotRegistered] = useState(false);
  const [generalError, setGeneralError] = useState(false);

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    setUserNotRegistered(false);
    try {
      await axios.post('/api/reset-password', { email });
      setLoading(false)
      setMailSent(true);
      setEmail('')
    } catch (error: any) {
      if(Number(error?.response?.status) === 400) {
        if(error?.response?.data?.message === "emailNotFound") {
          setUserNotRegistered(true);
        } else {
          setGeneralError(true);
        }
      }
      setLoading(false)
    } 
  };

  return (
    <div className="flex flex-col justify-center items-center mt-4">
      <div className="px-12">
        <p className={mailSent || userNotRegistered || generalError ? "hidden" : ""}>
          Fyll i din e-post så kommer du att få en länk där du kan återställa ditt lösenord.
        </p>
        <p className={!mailSent ? "hidden" : ""}>
          En återställningslänk har skickats till din epostadress.
        </p>
        <p className={!userNotRegistered ? "hidden" : "text-red"}>
          Denna mailadress finns inte registrerad.
        </p>
        <p className={!generalError ? "hidden" : "text-red"}>
          Något gick fel. Försök igen om en stund.
        </p>
      </div>
      {!mailSent &&
      <form id="passwordResetForm" onSubmit={(e) => handleOnSubmit(e)} className="relative mt-7 w-full">
        <input
          type="email"
          id="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          className="peer h-[60px] w-full rounded-full bg-white px-7 text-black outline-none ring-1 ring-inset ring-black"
          placeholder=" "
        />
        <label
          htmlFor="email"
          className="pointer-events-none absolute left-5 xs:left-7 top-3 -translate-y-1/2 text-small transition-all
                peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base
                peer-focus:top-3 peer-focus:text-small">
          Ange din e-post
        </label>
        <button type="submit" className="absolute top-1/2 right-7 -translate-y-1/2 hidden sm:block">
          <ArrowRight />
        </button>
      </form>
      }
      <div className="w-full sm:self-end">
        <SubmitButton disabled={!email.length} form="passwordResetForm" label="Skicka" loading={loading} className="h-[60px] px-10 mt-4 sm:hidden w-full"/>
      </div>
      <Symbol color="#ADB7AF" className="absolute -left-32 top-28 -z-10 -rotate-[70deg]" />
      <Symbol color="#ADB7AF" className="absolute -right-32 bottom-16 -z-10 -rotate-[-128deg]" />
    </div>
  );
};

export default ForgotPasswordForm;
