import React from 'react';
import type { CategoryList as ICategoryList } from 'types/CategoryList.interface';
import Carousel from 'components/Carousel';
import CategoryCard from 'components/CategoryCard';

const CategoryList: React.FC<ICategoryList> = ({ categories }) => {
  if(!categories?.length) return null

  return (
    <div className="container mb-8 sm:mb-14 md:mb-20 lg:px-16 category-list">
      <div className="mx-auto max-w-7xl">
        <Carousel
          title="Kategorier"
          breakPoints={[
            { width: 1, itemsToShow: 2.1 },
            { width: 560, itemsToShow: 4 },
            { width: 900, itemsToShow: 4 },
            { width: 1100, itemsToShow: 5 },
            { width: 1400, itemsToShow: 6 },
          ]}>
          {categories?.map((category, idx) => (
            <div className="w-full p-3" key={idx}>
              <CategoryCard category={category} noImage />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default React.memo(CategoryList);
