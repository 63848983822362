module.exports = {
  mode: 'jit',
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    screens: {
      xs: '30rem',
      sm: '48rem',
      md: '64rem',
      lg: '90rem',
      xl: '120rem',
    },
    container: {
      center: true,
      padding: {
        DEFAULT: '1.25rem',
        md: '1.25rem',
        lg: '1.25rem',
      },
    },
    colors: {
      black:        '#191B16',
      white:        '#FFFFFF',
      gray:         '#191b16',
      lightGray:    '#424242',
      lighterGray:  '#888888',
      lightestGray: '#EBEBEB',
      cream:        '#F7F7F7',
      red:          '#F4515A',
      teal: {
        100: '#E6EEEF ',
        200: '#ABC7CB',
        300: '#8FBBC3',
        400: '#79A2AE',
        500: '#55808C',
      },
      brown: {
        100: '#EFEEED',
        200: '#CAC5C3',
        300: '#B8ACA7 ',
        400: '#8D797A',
        500: '#7D6464',
      },
      green: {
        100: '#EDEFED',
        200: '#C2C9C3',
        300: '#ADB7AF',
        400: '#849A8D',
        500: '#687E77',
      },
      rose: {
        100: '#F3EDED',
        200: '#E9C5C5',
        300: '#E0ACB0',
        400: '#D77B8B',
        500: '#B06672',
      },
      blue: {
        100: '#E6EBEF',
        200: '#ABBDCB',
        300: '#9BAEC0',
        400: '#7488A1',
        500: '#5A708C',
      },
    },
    fontSize: {
      small: ['12px', '26px'],
      semiSmall: ['14px', '21.56px'],
      button: ['16px', '26px'],
      footer: ['18px', '25.2px'],
      base: ['18px', '26px'],
      preamble: ['22px', '30px'],
      h4: ['22px', '26px'],
      medium: ['26px', '34px'],
      h3: ['32px', '38px'],
      h2: ['42px', '50px'],
      h1: ['64px', '64px'],
      big: ['100px', '100px'],
    },
    fontFamily: {
      sans: ['Outfit', 'sans-serif'],
      body: ['Inter', 'sans-serif'],
    },
    extend: {
      backgroundImage: {
        'glas-glitter': "url(/assets/glas_glitter.png)",
      },
      maxWidth: {
        '7xl': '120rem',
      },
      padding: {
        22: '5.625rem',
      },
      flex: {
        full: '1 0 100%',
      },
      margin: {
        '15vw': '15vw',
      }
    },
  },
  plugins: [],
};
