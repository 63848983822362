import React from 'react';
import { useState } from 'react';
import Router from 'next/router'
import SubmitButton from 'components/SubmitButton';
import TextInput from 'components/TextInput';
import Link from 'next/link';
import axios from 'axios';

type Props = {
  setShowLogin?: () => void,
  setOpen?: (open: boolean) => void
  favorite?: string
};

const SignupForm: React.FC<Props> = ({ setShowLogin, setOpen, favorite, ...props }) => {

  const [email, setEmail] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailAlreadyTaken, setEmailAlreadyTaken] = useState(false);

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setValid(true);
    setEmailAlreadyTaken(false);
    try {
      const response = await axios.post('/api/auth/signup', { email });
      if(response?.data?.user?.id && !!favorite) {
        await axios.post(`/api/auth/favourites/${favorite}`)
      }
      if (setOpen) setOpen(false)
      window.location.href = Router.asPath;
    } catch (error: any) {
      if ((Number(error?.response?.status) === 400) && (error?.response?.data?.message === 'emailAlreadyTaken')) {
          setEmailAlreadyTaken(true);
      }
      setValid(false);
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <form onSubmit={(e) => handleOnSubmit(e)}>
        {valid ? '' :
            emailAlreadyTaken
              ? <div className="mb-3">Den här e-postadressen används redan.</div>
              : <div className="mb-3">Något gick fel. Kolla din e-postadress.</div>
        }
        <div className="relative w-full px-10 pt-0 pb-6">
          <TextInput
            border
            hideLabel
            id="email"
            type="email"
            label="Din e-postadress"
            placeholder="Din e-postadress"
            value={email}
            error={!valid}
            set={setEmail}
          />
        </div>

        <div className="relative w-full px-10 pb-4 flex flex-row">
          <div className=" flex justify-end">
            <SubmitButton label="Kom igång" loading={loading} />
          </div>
        </div>
        <div className="flex flex-col pt-0 p-10 text-left"><p>Genom att registrera ett konto godkänner du våra villkor</p>
          <Link href="/privacy-policy">
            <a className="underline">Personuppgiftspolicy</a>
          </Link>
        </div>
      </form>
      <div className="w-full bg-black p-6 rounded-b-2xl flex flex-col xs:flex-row justify-center">
        <p className="text-white">Har du redan ett konto?</p>
        <button onClick={setShowLogin}>
          <a className="ml-2 text-white underline">Logga in</a>
        </button>
      </div>
    </>
  );
};

export default SignupForm;
