import React, { useState, useMemo } from 'react';
import { splittList, scrollToId } from 'utils';
import EducationCard from '../components/EducationCard';
import Pagination from '../components/Pagination';
import { Article as IArticle } from '../types/Article.interface';

interface IProps {
  articles: IArticle[];
}

const ArticleList: React.FC<IProps> = ({ articles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const splittedArticles = splittList(articles);
  const currentTableData = useMemo(() => splittedArticles[currentPage - 1] || [], [currentPage, splittedArticles]);

  return (
    <div
      id="article-list"
      className="container scroll-mt-5 border-b-[1px] border-black border-opacity-10 pb-8 md:my-10 md:pb-14">
      <div className="mx-auto max-w-[1400px] ">
        <div className="grid gap-x-6 gap-y-14 sm:grid-cols-2 md:grid-cols-3">
          {currentTableData.map((article, idx) => (
            <EducationCard key={idx} {...article} />
          ))}
        </div>
        <Pagination
          scrollTo={() => scrollToId('#article-list')}
          totalPageCount={splittedArticles.length}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default React.memo(ArticleList);
