import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Provider, Show } from 'types/Show.interface';
import { useStreaming } from 'context/StreamContext';
import { useProfile } from 'context/UserContext';
import { getShowCardStatus } from 'utils';
import axios from 'axios'

import Play from 'icons/Play';
import CategoryTag from './CategoryTag';
import Dialog from 'components/Dialog';
import SignupForm from 'components/SignupForm';
import LoginForm from 'components/LoginForm';
import BookmarkFill from 'icons/BookmarkFill';
import Spinner from "icons/Spinner";
import VimeoPlayButton from './VimeoPlayButton'

declare const window: any;

export enum ShowStatus {
  Live =      'live',
  Ondemand =  'ondemand',
  Upcoming =  'upcoming',
}

export enum ShowCardPlace {
  List =     "list",
  Grid =     "grid",
  Search =   "search",
  Featured = "featured",
}

type Props = {
  show:             Show;
  text?:            'white' | 'black';
  place:            ShowCardPlace;
  outsidePlayBtn?:  any;
};

const ShowCard: React.FC<Props> = ({ show, place, text = 'black', outsidePlayBtn }) => {
  const { dispatch, startZellma, startSpockee  } = useStreaming();
  const profileDispatch = useProfile().dispatch
  const userState = useProfile().state

  const [showLogin, setShowLogin] = useState(false)
  const [bookmarkClicked, setBookmarkClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status] = useState(getShowCardStatus(show.live_at, show.duration));
  const playOndemandRef = useRef<any>(null);
  const playLiveRef = useRef<any>(null);

  const favouriteShows = userState?.favourites?.shows ?? [];
  const isFavourite = !!favouriteShows.find(o => o.id === show.id)

  const date = new Date(show.live_at.replace(/-/g, '/'));
  const day = ("00" + date.getDate()).slice(-2)
  const month = date.toLocaleString('default', { month: 'long' });
  const time = ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2)

  const mobileTextCssClass = status === ShowStatus.Live && text === 'white' ? 'text-white' : 'text-black';

  const registerShowStart = async () => {
    // Custom tracking event
    try {
      const res = await axios.post('/api/event/', { user_id: userState?.user?.id, show_id: show.id })
    } catch (error: any) {
      console.log(error);
    }

    // GTM event
    window.dataLayer?.push({
      event: "show_start",
      show_name: show.title,
      brand: show.brand.name,
      category: show.category.name
    })
  }


  // handle edge case of wrong ShowID
  // make UI accessible
  useEffect(() => {
    // only run once!
    if (window.scriptErrorLogger === true) {
      return
    }

    // remove overlay and turn stream context off!
    window.onerror = (args:any) => {
      window.scriptErrorLogger = true  

      console.log('On error found and closed show player')
      const player = document.querySelector('streamify-modal');
      const body = document.querySelector('body');
      const closeBtn = player?.shadowRoot?.querySelector('div.icon-button.close');
      closeBtn?.dispatchEvent(new Event('click'));
      body?.setAttribute('style', ' ');
      dispatch({ type: 'remove_id' });
    }

  })

  const playShow = () => {
    if (show.provider === Provider.streamify) {
      dispatch({ type: 'add_streamify_id', id: show.provider_id });
    } else if(show.provider === Provider.zellma) {
      startZellma(show.provider_id)
    } else if(show.provider === Provider.spockee) {
      startSpockee(show.provider_id)
    }
  }

  useEffect(() => {
    if(playOndemandRef && playOndemandRef.current) {
      playOndemandRef.current.addEventListener('click', registerShowStart)
    }
    if(playLiveRef && playLiveRef.current) {
      playLiveRef.current.addEventListener('click', registerShowStart)
    }
    if (show.provider === Provider.bambuser) {
      if (!window.initBambuserLiveShopping) {
        window.initBambuserLiveShopping = function (item: any) {
          window.initBambuserLiveShopping.queue.push(item);
        };
        window.initBambuserLiveShopping.queue = [];
      }
      window.onBambuserLiveShoppingReady = (player: any) => {
        // player = Bambuser Player API
        player.configure({
          buttons: {
            dismiss: player.BUTTON.MINIMIZE,
            product: player.BUTTON.LINK,
          },
          floatingPlayer: {
            navigationMode: player.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL,
          },
        });
        window.onpopstate = function () {
          // Close the current player and remove it from the DOM
          player.close();
        };
      };
      if(playOndemandRef && playOndemandRef?.current) {
        window.initBambuserLiveShopping({
          showId: show.provider_id,
          node: playOndemandRef.current,
          type: 'overlay',
        });
      }
      if(playLiveRef && playLiveRef?.current) {
        window.initBambuserLiveShopping({
          showId: show.provider_id,
          node: playLiveRef.current,
          type: 'overlay',
        });
      }
      if(outsidePlayBtn && outsidePlayBtn?.current) {
        window.initBambuserLiveShopping({
          showId: show.provider_id,
          node: outsidePlayBtn.current,
          type: 'overlay',
        });
      }
    } else {
      if(outsidePlayBtn && outsidePlayBtn?.current) {
        outsidePlayBtn.current.addEventListener('click', registerShowStart);
        outsidePlayBtn.current.addEventListener('click', playShow);
      }
    }

    return () => {
      if(playOndemandRef && playOndemandRef.current) {
        playOndemandRef.current.removeEventListener('click', registerShowStart)
      }
      if(playLiveRef && playLiveRef.current) {
        playLiveRef.current.removeEventListener('click', registerShowStart)
      }
      if(outsidePlayBtn && outsidePlayBtn?.current) {
        outsidePlayBtn.current.removeEventListener('click', registerShowStart);
        outsidePlayBtn.current.removeEventListener('click', playShow);
      }
    }
  }, [show.provider, show.provider_id]);

  const toggleFavouriteShow = async () => {
    setIsLoading(true);
    const res = await axios.post(`/api/auth/favourites/shows/${show.id}`)
    setBookmarkClicked(true);
    if(res?.data?.favourites?.shows) {
      setIsLoading(false);
      if(!isFavourite) {
        setBookmarkClicked(false);
      }
      profileDispatch({ type: 'update_favourite_shows', shows: res?.data?.favourites?.shows })
    } else {
      setIsLoading(false);
      profileDispatch({ type: 'update_favourite_shows' })
    }
  }

  let styles = "w-[150px] h-[195px] xs:w-[209px] xs:h-[271px] sm:w-[239px] sm:h-[310px] md:w-[312px] md:h-[525px] lg:w-[297px] lg:h-[500px] xl:w-[325px] xl:h-[547px]";
  if(place === ShowCardPlace.Featured)
    styles = "w-[248px] h-[322px] xs:w-[344px] xs:h-[579px] xl:w-[420px] xl:h-[700px]";
  if(place === ShowCardPlace.Search)
    styles = "w-[312px] h-[405px] xs:w-[209px] xs:h-[271px] sm:w-[164px] sm:h-[213px] md:w-[228px] md:h-[384px] lg:w-[297px] lg:h-[500px] xl:w-[325px] xl:h-[547px]";
  if(place === ShowCardPlace.Grid)
    styles = "w-[144px] h-[187px] xs:w-[209px] xs:h-[271px] sm:w-[352px] sm:h-[457px] md:w-[312px] md:h-[525px] lg:w-[297px] lg:h-[500px] xl:w-[325px] xl:h-[547px]";

  return (
    <>
      <div
        className={`group dropshadow relative mx-auto overflow-hidden rounded-3xl bg-white ${styles}`}
        style={{ backgroundColor: show.category?.color ?? '#ffffff' }}
        data-brand-name={show.brand.slug}
        data-brand-id={show.brand.id}
        data-show-id={show.id}
        data-show-name={show.title}
        data-provider-id={show.provider_id}
      >
        <div className="card-gradient md:group-hover:card-gradient-hover absolute h-full w-full p-4 md:p-6">
          <div className="relative h-full w-full">
            {(status === ShowStatus.Ondemand || status === ShowStatus.Upcoming) && (
                <div className="absolute top-0 left-0 right-0 z-10 flex items-start justify-between md:justify-start md:group-hover:justify-between">
                  <Link href={show.brand.url} passHref>
                    <div className="flex items-start justify-between
                    static before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-2/3 before:h-full cursor-pointer">
                      <a href={show.brand.url} className="relative h-10 w-20 md:h-[40px] md:w-[100px] m-auto" title={`Visa fler från ${show.brand.name}`}>
                        { show?.brand?.logo_white?.length && <Image
                            src={show?.brand?.logo_white}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                        }
                      </a>
                    </div>
                  </Link>
                <div className="pb-5 md:pb-10 md:hidden md:group-hover:flex">
                  <Dialog
                    title={showLogin ? "Logga in" : "Skapa konto"}
                    button={
                      <button
                        title={isFavourite ? "Sparad ✓" : "Spara show"}
                        onClick={toggleFavouriteShow}
                        onMouseLeave={() => { setBookmarkClicked(false) }}
                        className={`btn-white-icon btn-icon-md hover:${bookmarkClicked ? 'text-black' : 'text-rose-400'} ${isFavourite ? 'text-rose-400' : 'text-opacity-20'} ${isLoading ? "opacity-70" : ""}`}>
                        {isLoading ? <Spinner /> : <BookmarkFill />}
                      </button>
                    }>
                    {showLogin
                        ? <LoginForm setShowSignUp={() => setShowLogin(false)} favorite={`shows/${show.id}`} />
                        : <SignupForm setShowLogin={() => setShowLogin(true)} favorite={`shows/${show.id}`} />
                    }
                  </Dialog>
                </div>
              </div>
            )}
            {status === ShowStatus.Live && (
              <div className="absolute top-0 left-0 right-0 z-10 flex items-start justify-between">
                <Link href={show.brand.url}>
                  <a className="relative h-[40px] w-[100px]" title={`Visa fler från ${show.brand.name}`}>
                    { show?.brand?.logo_white?.length && <Image
                        src={show?.brand?.logo_white}
                        alt=""
                        layout="fill"
                        objectFit="contain"
                        objectPosition="top"
                        quality={100}
                    />
                    }
                  </a>
                </Link>
                <div className="ml-2 flex space-x-2 text-small">
                  <div className="flex items-center justify-center rounded-lg bg-red px-2 py-[6px] leading-none text-white">
                    <span className="mr-2 block h-[6px] w-[6px] rounded-full bg-white bg-opacity-80"></span>
                    <span className="whitespace-nowrap text-[11px]">Live nu</span>
                  </div>
                </div>
              </div>
            )}
            {status === ShowStatus.Ondemand && (
              <div className="relative h-1/2 top-1/2 left-0 right-0 z-20 flex -translate-y-1/2 items-center justify-center text-center cursor-pointer">
                {
                  show.provider === Provider.vimeo
                  ? <VimeoPlayButton providerId={show.provider_id}/>
                  : <button
                    aria-label="play show"
                    ref={playOndemandRef}
                    className="btn-white flex h-[39px] w-[39px] items-center justify-center md:h-[46px] md:group-hover:w-auto bg-opacity-80
                      static before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:h-full"
                    onClick={() => {
                      if (show.provider === Provider.streamify) {
                        dispatch({ type: 'add_streamify_id', id: show.provider_id })
                      } else if(show.provider === Provider.zellma) {
                        startZellma(show.provider_id)
                      } else if(show.provider === Provider.spockee) {
                        startSpockee(show.provider_id)
                      }
                    }}>
                    <span className="mt-[2px] ml-[2px]">
                      <Play />
                    </span>
                    <span className="ml-2 hidden md:group-hover:inline-block ">Spela show</span>
                  </button>
                }
              </div>
            )}
            {status === ShowStatus.Upcoming && (
              <div className="relative h-1/2 top-1/2 left-0 right-0 z-20 flex -translate-y-1/2 items-center justify-center text-center">
                <button disabled className="btn-white flex items-center justify-center whitespace-nowrap leading-4 md:h-11 bg-opacity-80
                  static before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:h-full">
                  <span className=" text-small md:text-semiSmall mr-2 h-[6px] w-[6px] rounded-full bg-red"></span> {day} {month} {time}
                </button>
              </div>
            )}
            {status === ShowStatus.Live && (
              <div className="relative h-1/2 top-1/2 left-0 right-0 z-20 flex -translate-y-1/2 items-center justify-center text-center cursor-pointer">
                {
                  show.provider === Provider.vimeo
                  ? <VimeoPlayButton providerId={show.provider_id}/>
                  : <button
                      ref={playLiveRef}
                      className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-white bg-opacity-80 p-0"
                      onClick={() => {
                        if (show.provider === Provider.streamify) {
                          dispatch({ type: 'add_streamify_id', id: show.provider_id });
                        } else if(show.provider === Provider.zellma) {
                          startZellma(show.provider_id)
                        } else if(show.provider === Provider.spockee) {
                          startSpockee(show.provider_id)
                        }
                      }}>
                      <Play />
                    </button>
                }
              </div>
            )}
            <div className="absolute bottom-0 left-0 right-0 z-10 w-full md:cursor-pointer">
              <div className="hidden md:block">
                {!!show.category && <CategoryTag category={show.category.name} color={show.category.color} />}
                <Link href={show.brand.url}>
                  <a className="relative h-[40px] w-[100px] z-10" title={`Visa fler från ${show.brand.name}`}>
                    <h4 className="max-h-[78px] overflow-hidden mt-4 text-white">{show.brand.name}</h4>
                  </a>
                </Link>
                <Link href={show.url}>
                  <a className="static before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:h-full cursor-pointer">
                    <h4 className="max-h-[78px] overflow-hidden heading-h4 mt-1 text-white">{show.title}</h4>
                  </a>
                </Link>
              </div>
            </div>

          </div>
        </div>
        { show?.image.length > 0 && <Image
            src={show?.image}
            alt=""
            layout="fill"
            sizes="(min-width: 1024px) 300px,
                   150px"
            objectFit="cover"
            objectPosition="center"
            className="-z-10"
            loading="lazy"
            quality={87}
          />
        }
      </div>
      <div className="mt-2 md:mt-4 md:hidden">
        <Link href={show.url}>
          <a>
            <h4 className={`heading-h4 max-h-[78px] overflow-hidden ${mobileTextCssClass}`}>{show.title}</h4>
          </a>
        </Link>
        <Link href={show.brand.url}>
          <a className="relative h-[40px] w-[100px]" title={`Visa fler från ${show.brand.name}`}>
            <h4 className={`text-semiSmall max-h-[78px] overflow-hidden mt-1 sm:mb-1 ${mobileTextCssClass}`}>{show.brand.name}</h4>
          </a>
        </Link>
        {!!show.category && <CategoryTag category={show.category.name} color={show.category.color} textCssClass={mobileTextCssClass} />}
      </div>
      {show.provider === Provider.streamify && (
        <div
          dangerouslySetInnerHTML={{
            __html: `<streamify-liveshopping id="${show.provider_id}" hide="true"></streamify-liveshopping>`,
          }}></div>
      )}
    </>
  );
};

export default React.memo(ShowCard);
