import React from 'react';

type Props = {
  size?: string;
};

const X: React.FC<Props> = ({ size = '1.5rem' }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: size, height: size }}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </>
  );
};

export default X;
