import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Dialog from 'components/Dialog';
import SignupForm from 'components/SignupForm';
import Link from 'next/link';
import LoginForm from 'components/LoginForm';
import { useProfile } from 'context/UserContext';

interface SignupContent {
  title: string;
  description: string;
  button_text: string;
  background_image: string | null;
}

type SignupProps = {
  name: string;
  content: SignupContent;
};

const fallbackImage: string = '/assets/glas_glitter.png';

const Signup: React.FC<SignupProps> = ({ name, content }) => {
  const [showLogin, setShowLogin] = useState(false);
  const typingRef: any = useRef(null);

  useEffect(() => {
    if (!typingRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const text = content.title;
        let textPosition = 0;
        const typer = () => {
          const el = typingRef.current?.querySelector('#typing-text');
          if (!el) return;
          el.innerHTML = text.substring(0, textPosition);
          if (textPosition++ !== text.length) {
            setTimeout(typer, 100);
          }
        };
        typer();
        observer.disconnect();
      }
    });
    observer.observe(typingRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return !!useProfile().state.user ? null : (
    <div ref={typingRef} className="container relative mb-8 sm:mb-14 md:mb-20">
      <div className="relative mx-auto max-w-7xl overflow-hidden sm:rounded-2xl">
        <Image
          src={content.background_image || fallbackImage}
          alt="glas with glitter background image"
          layout="fill"
          quality={100}
          objectFit="cover"
          objectPosition="center"
          className="-z-10"
        />
        <div className="relative flex flex-col justify-center py-20 text-center">
          <div className="mx-auto min-w-[40vw] max-w-[520px] text-left text-center sm:min-w-[30vw] md:min-w-[30vw] lg:min-w-[25vw]">
            <h1 id="typing-text" className="heading-h1">
              &nbsp;
            </h1>
          </div>
          <p className=" py-4">{content.description}</p>
          {name.includes('company') ? (
            <Link href="https://customer.showroom.shopping/">
              <a className="btn-black z-10 m-auto" target="_blank">
                {content?.button_text}
              </a>
            </Link>
          ) : (
            <Dialog
              title={showLogin ? 'Logga in' : 'Skapa konto'}
              button={<button className="btn-black z-10 m-auto">{content.button_text}</button>}>
              {showLogin ? (
                <LoginForm setShowSignUp={() => setShowLogin(false)} />
              ) : (
                <SignupForm setShowLogin={() => setShowLogin(true)} />
              )}
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Signup);
