import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { ColorHero as IColorHero } from '../types/ColorHero.interface'
import ReactPlayer from 'react-player'
import Symbol from "../components/Symbol";

const VideoContent = ({ videoUrl, color, videoSettings }: { videoUrl: string, color: string | undefined, videoSettings: any }) => {
  const onErrorHandle = () => {
  }

  return <div
    className={`group flex justify-center items-center relative max-w-4xl aspect-[16/9] overflow-hidden rounded-3xl mx-auto sm:-mt-12 z-0`}
    style={{ backgroundColor: color }}
  >
    <ReactPlayer
      playing={videoSettings ? !!videoSettings.playing : true}
      controls={videoSettings ? !videoSettings.hideControls : false}
      loop={videoSettings ? !!videoSettings.loop : true}
      muted={videoSettings ? !!videoSettings.muted : true}
      playsinline={true}
      width='100%'
      height='100%'
      url={videoUrl}
      onError={onErrorHandle}
    />
  </div>
}

const ColorHero: React.FC<IColorHero> = ({ content }) => {
  const { title, description, background_color, image, video_url, bows_color } = content;
  const [missingImage, setMissingImage] = React.useState(false);

  const ImageContent = ({ image }: { image: string }) => {
    return <div className="relative max-w-4xl aspect-[16/9] overflow-hidden rounded-3xl object-cover mx-auto sm:-mt-12">
      <Image
        src={image}
        alt=""
        className="object-cover"
        layout="fill"
        loading="lazy"
        quality={100}
        onError={() => setMissingImage(true)}
      />
    </div>
  }

  return (
    <div className="mb-14 md:mb-20">
      <div style={{ background: background_color ?? "#ededed" }}>
        <div className="flex flex-col items-center justify-center p-5 min-h-[150px] sm:p-16">
          <div className="max-w-[800px] text-center z-10">
            <h1 className="heading-h2 mb-4">{title}</h1>
            {description ? <p className="text-preamble">{description}</p> : null}
          </div>
          {bows_color &&
            <>
              <Symbol
                color={bows_color}
                className="absolute left-0 hidden translate-y-44 translate-x-8 rotate-[-75deg] sm:block z-0"
              />
              <Symbol
                color={bows_color}
                className="absolute right-0 hidden -translate-y-28 -translate-x-14 rotate-[-165deg] sm:block z-0"
              />
              <Symbol
                color={bows_color}
                className="absolute right-0 hidden translate-y-40 translate-x-10 rotate-[0deg] sm:block z-0"
              />
            </>
          }
        </div>
      </div>
      <div className="px-4" style={{ background: `linear-gradient(180deg, ${background_color} 40%, rgba(0,0,0,0) 40%` }}>
        {
          video_url
            ? <VideoContent videoUrl={video_url} color={content.color} videoSettings={content.video_settings} />
            : image && !missingImage
              ? <ImageContent image={image} />
              : null
        }
      </div>
    </div>
  );
};
export default React.memo(ColorHero);
