import React, { createRef, useEffect, useState } from 'react';
import Link from 'next/link';
import ReactElasticCarousel from 'react-elastic-carousel';

import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';

type Props = {
  title: string;
  isToplist?: boolean;
  isStartList?: boolean;
  elastic?: boolean;
  children?: React.ReactNode;
  breakPoints?: { width: number; itemsToShow: number }[];
  showAllBtn?: React.ReactNode;
  linkAll?: string;
};

let startX: number, currentX: number;

const touchMoveHandler = (e: any) => {
  const threshold = 5;
  currentX = e.touches[0].clientX - startX;
  if (Math.abs(currentX) > threshold) {
    e.preventDefault();
    e.returnValue = false;
    return false;
  }
};

const touchStartHandler = (e: any) => {
  startX = e.touches[0].clientX;
};

const Carousel: React.FC<Props> = ({
  title,
  isToplist,
  isStartList,
  children,
  breakPoints,
  elastic = false,
  showAllBtn,
  linkAll,
}) => {
  let containerRef = createRef<HTMLDivElement>();
  const [windowWidth, setWindowWidth] = useState(0);
  const shouldRenderArrows = () => {
    const listLength = React.Children.count(children);
    if (listLength > 4 && windowWidth >= 1024) {
      return true;
    } else if (listLength > 3 && windowWidth >= 900) {
      return true;
    } else if (listLength > 2 && windowWidth < 900) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStartHandler);
      containerRef.current.addEventListener('touchmove', touchMoveHandler, { passive: false });
    }

    const containerRefVar = containerRef.current;

    return () => {
      if (containerRefVar) {
        containerRefVar.removeEventListener('touchstart', touchStartHandler);
        containerRefVar.removeEventListener('touchmove', touchMoveHandler);
      }
    };
  });

  return (
    <div ref={containerRef} className="relative">
      <div className="mb-4 flex items-center justify-between md:mb-6 md:mb-3">
        {isToplist ? (
          <h2 className={'heading-h2 md:pb-10'}>{title}</h2>
        ) : (
          <h3 className={`heading-h3 ${isStartList ? 'sm:text-white' : ''}`}>{title}</h3>
        )}
        {shouldRenderArrows() ? (
          <div className="md:hidden">
            <Link href={linkAll ?? '/shows'}>
              <a className="flex items-center">
                <span className="mr-3 font-medium">Alla</span>
                <ArrowRight />
              </a>
            </Link>
          </div>
        ) : null}
      </div>

      <ReactElasticCarousel
        isRTL={false}
        pagination={false}
        showArrows={shouldRenderArrows()}
        renderArrow={({ type, isEdge, onClick }) => {
          const pointer = type === 'PREV' ? <ArrowLeft /> : <ArrowRight />;
          const position = type === 'PREV' ? 'right-[35px]' : 'right-0';
          return (
            <>
              <button
                className={`absolute top-[10px] hidden md:block ${isEdge ? 'opacity-60' : ''} ${position}`}
                onClick={onClick}>
                {pointer}
              </button>
            </>
          );
        }}
        itemPadding={[0, 0, 0, 0]}
        outerSpacing={-12}
        showEmptySlots={true}
        breakPoints={
          breakPoints ?? [
            { width: 1, itemsToShow: 2.1 },
            { width: 560, itemsToShow: 3 },
            { width: 900, itemsToShow: 3 },
            { width: 1024, itemsToShow: 4 },
            { width: 1440, itemsToShow: 5 },
          ]
        }
        className={elastic ? 'items-elastic' : ''}>
        {children}
        {showAllBtn}
      </ReactElasticCarousel>
    </div>
  );
};

export default Carousel;
