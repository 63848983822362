import React, { useState } from 'react';
import Link from 'next/link';
import axios from 'axios';
import { Product as IProduct } from 'types/Products.interface';
import Dialog from 'components/Dialog';
import SignupForm from 'components/SignupForm';
import LoginForm from 'components/LoginForm';
import HeartFill from 'icons/HeartFill';
import Image from 'next/image';
import { useProfile } from 'context/UserContext';
import { getAspectRatioCSS } from 'utils';
import Spinner from '../icons/Spinner';

const ProductCard: React.FC<IProduct> = ({ id, image, name, description, url }) => {
  const { dispatch } = useProfile();
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useProfile();
  const favouriteProducts = state?.favourites?.products ?? [];
  const isFavourite = !!favouriteProducts.find((o) => o.id === id);

  const toggleFavouriteProduct = async () => {
    setIsLoading(true);
    const res = await axios.post(`/api/auth/favourites/products/${id}`);
    setIsLoading(false);
    if (res?.data?.favourites?.products) {
      const { products } = res?.data?.favourites;
      dispatch({ type: 'update_favourite_products', products });
    } else {
      dispatch({ type: 'update_favourite_products' });
    }
  };

  return (
    <div className="relative cursor-pointer">
      <Link href={url}>
        <div>
          <div
            className={`group relative mx-auto aspect-[297/263] ${getAspectRatioCSS(
              1,
              1
            )} overflow-hidden rounded-2xl`}>
            {image && (
              <Image layout="fill" src={image} alt={name} className="h-full w-full object-cover object-center" />
            )}
          </div>
          <div className="mt-4">
            <p className="text-small font-normal uppercase opacity-50">{name}</p>
            <h4 className="heading-h4">{description}</h4>
          </div>
        </div>
      </Link>
      <div className="absolute top-3 right-3 z-10">
        <Dialog
          title={showLogin ? 'Logga in' : 'Skapa konto'}
          button={
            <button
              title={isFavourite ? 'Sparad ✓' : 'Spara produkt'}
              onClick={toggleFavouriteProduct}
              className={`btn-white-icon-outline btn-icon-md hover:text-red ${isFavourite ? 'text-red' : null} `}>
              {isLoading ? <Spinner /> : <HeartFill />}
            </button>
          }>
          {showLogin ? (
            <LoginForm setShowSignUp={() => setShowLogin(false)} favorite={`products/${id}`} />
          ) : (
            <SignupForm setShowLogin={() => setShowLogin(true)} favorite={`products/${id}`} />
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default React.memo(ProductCard);
