import React, { useEffect } from 'react';
import Link from 'next/link';
import { MainHero as IMainHero } from '../types/MainHero.interface';
import { scrollToId } from 'utils';
import Image from 'next/image';
import Player from '@vimeo/player';

const ImageContent = ({ image }: { image: string }) => {
  if (!image) return null;
  return (
    <Image
      src={image}
      alt="Main hero background"
      layout="fill"
      quality={100}
      objectFit="cover"
      objectPosition="center"
      className="-z-10"
    />
  );
};

const MainHero: React.FC<IMainHero> = ({ background, content, video_id, video_width, video_height }) => {
  const { button, title, description } = content;

  const scroll = () => {
    scrollToId(`#${content?.button?.anchor_id?.toLowerCase()}`);
  };

  useEffect(() => {
    if (!!video_id) {
      const iframe = document.querySelector('iframe');
      if (iframe) new Player(iframe);
    }
  });

  const videoBgStyle = !!video_id && !!background ? {
    background: 'url(' + background?.large + ')',
    backgroundSize: 'cover'
  } : {}

  return (
    <div className="">
      <div
        className={`relative flex justify-center overflow-hidden`}
        style={!!video_id ? { aspectRatio: video_width + '/' + video_height } : undefined}>
        {!video_id ? (
          !!background ? (
            <ImageContent image={background?.large} />
          ) : (
            ''
          )
        ) : !!video_id ? (
          <div id="vimeo-video" className="absolute h-full w-full" style={videoBgStyle}>
            <iframe
              id="main-vimeo-video"
              src={`https://player.vimeo.com/video/${video_id}?h=301d3a2b49&muted=1&autoplay=1&controls=0&loop=1&app_id=122963`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              data-ready="true"
            />
          </div>
        ) : (
          ''
        )}
        <div className={`mx-auto ${!!video_id ? 'h-full' : 'min-h-[580px]'} max-w-[1400px]`}>
          <div className={`flex ${!!video_id ? 'h-full' : 'min-h-[580px]'} flex-col items-center justify-center p-10`}>
            <div className="flex flex-col items-center text-center ">
              <h1 className="heading-big mb-[12px] text-white drop-shadow-lg sm:mb-4">{title}</h1>
              <p
                className={`heading-preamble container max-w-xl text-white drop-shadow-lg lg:max-w-[829px] ${
                  !!video_id ? 'hidden sm:block' : ''
                }`}>
                {description}
              </p>
            </div>
            {!!button.hide ? null : (
              <div className={`z-10 flex space-x-4 ${!!video_id ? 'hidden sm:mt-8 sm:block' : 'mt-8'}`}>
                {button.anchor_id ? (
                  <button className="btn-white cursor-pointer" onClick={scroll}>
                    {button.text}
                  </button>
                ) : (
                  <Link href={button.url || '/live'}>
                    <a className="btn-white">{button.text}</a>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!button.hide ? null : (
        <div className={`z-10 mt-6 flex w-full justify-center ${!!video_id ? 'sm:hidden' : 'hidden'}`}>
          {button.anchor_id ? (
            <button className="btn-black cursor-pointer" onClick={scroll}>
              {button.text}
            </button>
          ) : (
            <Link href={button.url || '/live'}>
              <a className="btn-black">{button.text}</a>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MainHero);
