import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { getShowCardStatus } from 'utils';
import { useRouter } from 'next/router';
import type { FeaturedShow as IFeaturedShow } from 'types/FeaturedShow.interface';
import ShowCard, { ShowCardPlace, ShowStatus } from 'components/ShowCard';
import Dialog from 'components/Dialog';
import SignupForm from 'components/SignupForm';
import LoginForm from 'components/LoginForm';
import BookmarkFill from 'icons/BookmarkFill';
import { useProfile } from 'context/UserContext';
import axios from 'axios';
import Spinner from '../icons/Spinner';

const FeaturedShow: React.FC<IFeaturedShow> = ({
  title,
  short_description,
  show,
  read_more,
  logo,
  logo_white,
  ...props
}) => {
  const { state } = useProfile();
  const profileDispatch = useProfile().dispatch;
  const [showLogin, setShowLogin] = useState(false);
  const [isOnShowPage, setIsOnShowPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const status = getShowCardStatus(show.live_at, show.duration);
  const isHomepage = useRouter().asPath === '/';
  const isLive = status === ShowStatus.Live;
  const favouriteShows = state?.favourites?.shows ?? [];
  const isFavourite = !!favouriteShows.find((s) => s.id === show.id);
  const outsidePlayBtn = useRef(null);

  const toggleFavouriteShow = async () => {
    setIsLoading(true);
    const res = await axios.post(`/api/auth/favourites/shows/${show.id}`);
    setIsLoading(false);
    if (res?.data?.favourites?.shows) {
      profileDispatch({ type: 'update_favourite_shows', shows: res?.data?.favourites?.shows });
    } else {
      profileDispatch({ type: 'update_favourite_shows' });
    }
  };

  useEffect(() => {
    setIsOnShowPage(window.location.pathname === show.url);
  }, [show]);

  const liveDate = show.live_at.substring(0, 10);
  const liveTime = show.live_at.substring(11, 16);

  return (
    <div>
      <div
        className={`px-8 py-8 md:py-10 md:px-16 md:py-28 ${
          isLive ? 'bg-black text-white' : isHomepage ? (!state.user ? '' : 'bg-blue-100') : 'bg-black bg-opacity-5'
        }`}>
        <div className="mx-auto max-w-[1260px]">
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 md:col-span-1">
              <div className="mx-auto max-w-[344px]">
                <ShowCard place={ShowCardPlace.Featured} show={show} text="white" outsidePlayBtn={outsidePlayBtn} />
              </div>
            </div>
            <div className="col-span-2 place-self-center md:col-span-1">
              <div className="max-w-lg">
                {isLive ? (
                  <div className="heading-h4 mb-8 flex items-center">
                    <span className="relative mr-2 h-[10px] w-[10px]">
                      <span className="absolute h-full w-full animate-ping rounded-full bg-[#CF131E]" />
                      <span className="absolute h-full w-full rounded-full bg-[#CF131E]" />
                    </span>
                    Live just nu
                  </div>
                ) : null}

                <Link href={show.brand.url}>
                  {/* @TODO: Need to update and look into this one */}
                  <img
                    src={isLive && logo_white ? logo_white : logo ?? null}
                    className="mb-4 max-h-[80px] max-w-[350px] cursor-pointer md:mb-5"
                    alt="show logotype"
                  />
                </Link>

                {isOnShowPage ? (
                  <h2 className="heading-h2 mb-5">{title}</h2>
                ) : (
                  <Link href={show.url}>
                    <a className="hover:underline">
                      <h2 className="heading-h2 mb-5">{title}</h2>
                    </a>
                  </Link>
                )}
                <div className="space-y-4 text-preamble">
                  <p>{short_description}</p>
                </div>
                {isOnShowPage && (
                  <p className="text-gray-400 mt-5 text-semiSmall">
                    Sändning: {liveDate} kl. {liveTime}
                  </p>
                )}
                <div className="mt-6 flex items-center space-x-5 md:mt-8">
                  {isLive || isHomepage ? (
                    <div className="flex flex-row items-center">
                      <button
                        ref={outsidePlayBtn}
                        className={`${isHomepage && !isLive ? 'btn-black' : 'btn-white'} mr-3 min-w-[130px]`}>
                        Spela video
                      </button>
                      <Link href={read_more.url}>
                        <a className="text-link">{read_more.label}</a>
                      </Link>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center">
                      <Dialog
                        title={showLogin ? 'Logga in' : 'Skapa konto'}
                        button={
                          <button
                            title={isFavourite ? 'Sparad ✓' : 'Spara show'}
                            onClick={toggleFavouriteShow}
                            className={`ml-2 flex h-[46px] w-[46px] items-center justify-center rounded-full ring-1 ring-inset ring-black ring-opacity-10 hover:bg-white hover:text-rose-400 group-hover:flex ${
                              isFavourite ? 'bg-white text-rose-400' : 'bg-black text-white'
                            }`}>
                            {isLoading ? <Spinner /> : <BookmarkFill />}
                          </button>
                        }>
                        {showLogin ? (
                          <LoginForm setShowSignUp={() => setShowLogin(false)} favorite={`shows/${show.id}`} />
                        ) : (
                          <SignupForm setShowLogin={() => setShowLogin(true)} favorite={`shows/${show.id}`} />
                        )}
                      </Dialog>
                      <p className="ml-3">Spara</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FeaturedShow);
