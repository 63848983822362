import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Article as IArticle } from '../types/Article.interface'

const EducationCard: React.FC<IArticle> = ({ title, image, description, url, ...props }) => {
  return (
    <Link href={url || ''}>
      <a>
        <div
          className="relative mb-6 aspect-[400/300] overflow-hidden rounded-3xl object-cover bg-rose-100"
        >
          { image && <Image
            src={image}
            alt=""
            layout="fill"
            quality={100}
            objectFit="cover"
            loading="lazy"
          />}
        </div>
        <h3 className="heading-h3 mb-4">{title}</h3>
        <p>{description}</p>
      </a>
    </Link>
  );
};

export default EducationCard;
