import React from 'react';

type Props = {
  size?: string;
};

const HeartFill: React.FC<Props> = ({ size = '1.5rem' }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: size, height: size }}
        viewBox="-1.5 -5 24 24"
        fill="currentColor">
        <path
          d="M10.4964 16.6607C10.4362 16.6607 10.3455 16.622 10.3178 16.6071C7.8178 15.2679 0.853516 10.4075 0.853516 5.71428C0.853516 2.75562 2.63923 0 6.03209 0C8.07981 0 9.60717 1.00376 10.4964 2.45012C11.3856 1.00376 12.9129 0 14.9607 0C18.3535 0 20.1392 2.75562 20.1392 5.71428C20.1392 10.4075 13.1749 15.2679 10.6749 16.6071C10.6472 16.622 10.5566 16.6607 10.4964 16.6607Z"
        />
      </svg>
    </>
  );
};

export default HeartFill;
