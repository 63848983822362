import React, { useState, useEffect, useCallback, useRef } from 'react';
import Dialog from 'components/Dialog';
import SignupForm from 'components/SignupForm';
import LoginForm from 'components/LoginForm';
import BookmarkFill from 'icons/BookmarkFill';
import { CategoryHero as ICategoryHero } from 'types/CategoryHero.interface';
import axios from 'axios'
import { useProfile } from "context/UserContext";
import Image from 'next/image';
import Player from '@vimeo/player';
import Spinner from "../icons/Spinner";

const CategoryHero: React.FC<ICategoryHero> = ({ gradient = false, image_hero, image_hero_portrait, video_hero, video_hero_portrait, content, category, ...props }) => {
  const { state } = useProfile();
  const profileDispatch = useProfile().dispatch;

  const { title, short_description } = content;
  const favouriteCategories = state?.favourites?.categories ?? [];
  const isFavourite = !!favouriteCategories.find(o => o.id === category.id);

  const [showLogin, setShowLogin] = useState(false)
  const [bookmarkClicked, setBookmarkClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [isPortrait, setIsPortrait] = useState<boolean>()
  const player = useRef<Player>()

  const initVideoPlayer = (currentIsPortrait: boolean) => {
    if (player.current) {
      player.current.destroy()
    }
    player.current = new Player('vimeo-video', {
      id: (currentIsPortrait && !!video_hero_portrait) ? parseInt(video_hero_portrait) : parseInt(video_hero),
      muted: true,
      controls: false,
      autoplay: true,
      loop: true,
      quality: "720p",
      title: false,
      width: 1880,
      responsive: (currentIsPortrait && !!video_hero_portrait)
    });
  }

  const toggleFavouriteCategory = async () => {
    setIsLoading(true)
    const res = await axios.post(`/api/auth/favourites/categories/${category.id}`)
    setIsLoading(false)
    if (res?.data?.favourites?.categories) {
      profileDispatch({ type: 'update_favourite_categories', categories: res?.data?.favourites?.categories })
    } else {
      profileDispatch({ type: 'update_favourite_categories' })
    }
  }

  const updateByOrientation = useCallback(() => {
    const currentIsPortrait = window.innerHeight > window.innerWidth

    if ((isPortrait !== undefined)
      && ((isPortrait && currentIsPortrait) || (!isPortrait && !currentIsPortrait))
    ) return
    setIsPortrait(currentIsPortrait)

    if (video_hero) initVideoPlayer(currentIsPortrait)

  }, [initVideoPlayer, isPortrait, video_hero])

  useEffect(() => {
    updateByOrientation()
    window.addEventListener('resize', updateByOrientation)
    return () => {
      window.removeEventListener('resize', updateByOrientation)
    }
  }, [updateByOrientation])

  const ImageContent = ({ image }: { image: string }) => {
    if (!image) return null
    return (
      <Image
        src={(isPortrait && image_hero_portrait && image_hero_portrait.length) ? image_hero_portrait : image}
        alt="category hero image"
        layout="fill"
        quality={100}
        objectFit="cover"
        objectPosition="center"
        className="-z-10"
        priority={true}
      />
    )
  }

  return (
    <div className="mb-8 sm:mb-14 md:mb-20">
      <div className="relative flex justify-end overflow-hidden">
        {!video_hero ? <ImageContent image={image_hero} /> :
          <div id="vimeo-video" className={(!!(isPortrait && video_hero_portrait) ? 'w-screen ' : 'xl:w-screen ') + "absolute bottom-[-100px]"} />
        }
        <div className="container">
          <div className="mx-auto max-w-[1400px] w-full">
            <div className="flex min-h-[580px] flex-col items-start justify-end p-10">
              <div className="max-w-2xl z-10">
                <h1 className="mt-4 heading-h1 mb-4 text-white drop-shadow-md">{title}</h1>
                <p className="text-preamble mb-4 text-white drop-shadow-md">{short_description}</p>
                <Dialog
                  title={showLogin ? "Logga in" : "Skapa konto"}
                  button={
                    <button
                      title={isFavourite ? "Sparad ✓" : "Spara show"}
                      onClick={toggleFavouriteCategory}
                      onMouseLeave={() => { setBookmarkClicked(false) }}
                      className={`btn-white-icon btn-icon-md hover:${bookmarkClicked ? 'text-black' : 'text-rose-400'} ${isFavourite ? 'text-rose-400' : 'text-opacity-20'} `}>
                      {isLoading ? <Spinner /> : <BookmarkFill />}
                    </button>
                  }>
                  {showLogin
                      ? <LoginForm setShowSignUp={() => setShowLogin(false)} favorite={`categories/${category.id}`} />
                      : <SignupForm setShowLogin={() => setShowLogin(true)} favorite={`categories/${category.id}`} />
                  }
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CategoryHero);
