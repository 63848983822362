import React from 'react';
import Spinner from "./Spinner";

type Props = {
  className?: string,
  color?: string,
  loading?: boolean
};

const ArrowRight: React.FC<Props> = ({ className, color = "#191B16", loading = false }) => {
  return (
    !loading ?
    <svg className={className} width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8.99988L22 8.99988" stroke={color} strokeWidth="2" />
      <path d="M13.7079 16.7781L21.486 8.99988L13.7079 1.2217" stroke={color} strokeWidth="2" />
    </svg>
    :
    <Spinner color={color} size="23" />
  );
};

export default ArrowRight;
