import React, { useEffect } from 'react';
import BrandsHero from './BrandsHero';
import BrandCard from 'components/BrandCard';
import { Category } from 'types/Category.interface';
import { Brand } from 'types/Brands.interface';

import type { BrandsAll as IBrandsAll } from 'types/BrandsAll.interface';

const BrandsAll: React.FC<IBrandsAll> = ({ brands, categories }) => {
  const [selectedCategories, setSelectedCategories] = React.useState<Category[]>([]);
  const [filteredBrands, setFilteredBrand] = React.useState<Brand[]>([]);

  useEffect(()=>{
    let newFilteredList = brands.filter(brand => {
      let isSelected = false;
      selectedCategories.map(selectedCategory => {
        if(brand.categories.map(c => c.name).includes(selectedCategory.name)){
          isSelected = true;
        }
      })
      return isSelected;
    })
    setFilteredBrand(selectedCategories.length > 0 ? newFilteredList : brands);
  }, [selectedCategories, brands])

  return (
    <>
      <BrandsHero setSelectedCategories={setSelectedCategories} categories={categories} title="Alla Showrooms" />
      <div className="container mb-8 sm:mb-14 md:mb-20">
        <div className="mx-auto max-w-[1400px]">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 items-start place-items-center gap-6 ">
            {filteredBrands?.map((brand, idx) => (
              <BrandCard {...brand} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(BrandsAll);
