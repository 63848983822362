import React from 'react';

type Props = {
  size?: string;
};

const BookmarkFill: React.FC<Props> = ({ size = '1.5rem' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
      viewBox="-4.5 -5 24 24"
      fill="currentColor">
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H14C14.5523 0 15 0.447715 15 1V14.3705C15 15.1166 14.2124 15.5999 13.5471 15.262L7.5 12.1905L1.45286 15.262C0.787613 15.5999 0 15.1166 0 14.3705V1Z"
      />
    </svg>
  );
};

export default BookmarkFill;
