import React from 'react';

interface AboutProps {
  content: {
    html: string;
  };
}

const CategoryAbout: React.FC<AboutProps> = ({ content, ...props }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-2xl sm:text-[22px]">{content.html}</div>
    </div>
  );
};

export default React.memo(CategoryAbout);
