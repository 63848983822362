import ArrowRight from "icons/ArrowRight";
import React from 'react';

type Props = {
  label: string;
  loading?: boolean;
  form?: string;
  className?: string;
  disabled?: boolean;
};

const TextInput: React.FC<Props> = ({ label, loading = false, form, className = "", disabled = false}) => {
	return (
    <button form={form} type="submit" className={`btn-black btn-large ${loading || disabled ? "bg-lighterGray" : ""} ${className}`} disabled={loading || disabled}>
			<span className="mr-3">{label}</span>
			<ArrowRight color="white" loading={loading} />
    </button>
	)
};

export default TextInput;
