import LogoIcon from "icons/LogoIcon";
import React from 'react';
import tw from '../../tailwind.config';

const FourColumn: React.FC = () => {

  const Column = ({ index, headline, text }: { index: number, headline: string, text: string }) => {
    return (
      <div className="col-span-6 md:col-span-1 bg-cream rounded-2xl p-6">
        <div className="flex justify-center mb-3">
          <LogoIcon
            index={index}
            iconColor={tw.theme.colors.rose["500"]}
            textColor={tw.theme.colors.rose["500"]}
          />
        </div>
        <div>
          <h4 className="heading-h3 md:heading-h4 mt-4 text-center">{headline}</h4>
          <p className="opacity-70 border-t border-rose-200 mt-4 pt-4">{text}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="px-4 md:px-6 lg:px-8 mb-8 sm:mb-14 md:mb-20 bg-rose-300">
      <div className="mx-auto max-w-[1400px]">
        <div className="flex flex-col items-center justify-center pt-16 pb-6">
          <h1 className="heading-h2 mb-7 text-center">Tillsammans framåt</h1>
          <div className="grid gap-6 md:grid-cols-4">
            {<Column
              index={1}
              headline="En plats att nå er målgrupp"
              text="Genom att samla Live Shopping på Showroom har tittarna nu fått en samlingsplats att starta sin resa i jakten på inspirerande innehåll. Ge fler möjlighet att upptäcka era shows genom ett Showroom hos oss. Vi strävar efter att vara teknikoberoende, så oavsett vilken teknik du använder för att skapa dina shows vill vi göra plats för er."
            />}
            {<Column
              index={2}
              headline="Live-strategi & idé"
              text={`Ni har säkert en väl etablerad varumärkesstrategi och satta kommunikationsmål - men hur översätts den Live? En ny kommunikationskanal kräver sin strategi. Vi hjälper er att ta ert varumärke Live!`}
            />}
            {<Column
              index={3}
              headline="Produktion"
              text="Det enda som behövs är en mobilkamera. Men ibland kanske idén och varumärket kräver en annan produktion, eller så är ni bara nyfikna på att lyfta er produktion till en annan nivå."
            />}
            {<Column
              index={4}
              headline="LiveShopping data"
              text="Brett innehåll ger en bred målgrupp. På Showroom kan du ta del av data och statistik och ges möjlighet att jämföra era shows mot andra inom samma kategori. Det ger er möjlighet att skapa relevanta KPI:er för era shows."
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FourColumn);
