import React from 'react';
import { getThemeColor } from 'utils';

type Props = {
  checked?: boolean;
};

const CheckBox: React.FC<Props> = ({ checked }) => {
  return (
    <div
      className="flex h-[16px] min-w-[16px] items-center justify-center rounded border-2 border-black"
      style={{ backgroundColor: checked ? 'black' : 'white' }}>
      <svg className="mt-[1px]" width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="white"
          d="M1.71048 3.06552C1.32183 2.67313 0.688673 2.6701 0.296285 3.05875C-0.0961038 3.4474 -0.0991333 4.08056 0.289518 4.47295L1.71048 3.06552ZM4.2 7L3.48952 7.70372L4.27955 8.50134L4.98087 7.6247L4.2 7ZM9.78087 1.6247C10.1259 1.19343 10.056 0.564141 9.62469 0.219131C9.19343 -0.125878 8.56414 -0.055957 8.21913 0.375305L9.78087 1.6247ZM0.289518 4.47295L3.48952 7.70372L4.91048 6.29628L1.71048 3.06552L0.289518 4.47295ZM4.98087 7.6247L9.78087 1.6247L8.21913 0.375305L3.41913 6.37531L4.98087 7.6247Z"
        />
      </svg>
    </div>
  );
};

export default CheckBox;
