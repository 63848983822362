import React, {useCallback, useEffect, useRef, useState} from 'react';
import ExternalLinkIcon from '../icons/ExternalLink';

type Props = {};

const BusinessNumbers: React.FC<Props> = () => {
  const Counter = ({ start, end }: { start: number, end: number }) => {
    const [counter, setCounter] = useState(start)
    const numbersRef: any = useRef(null)

    const countUp = useCallback(() => {
      const renderTime = 1000;
      let current = start
      let timer = setInterval(() => {
        if (current >= end - 1) {
          clearInterval(timer)
        }
        current++
        setCounter(current)
      }, renderTime/end)
    }, [end, start])

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          countUp()
          observer.disconnect()
        }
      })
      observer.observe(numbersRef.current);
      return () => {
        observer.disconnect()
      }
    },[countUp])

    return (
        <h1 ref={numbersRef} className="heading-h1">{counter}%</h1>
    );
  }

  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-[1400px]">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <div className="mx-auto max-w-[250px] text-center hover:scale-105 transition-all">
            <a href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/its-showtime-how-live-commerce-is-transforming-the-shopping-experience" target="_blank" rel="noreferrer">
              <Counter start={0} end={20} />
              <p className="mt-2 text-preamble">ökning i engagemang med LiveShopping.
                <span className="inline-block ml-2 -mb-[2px]"><ExternalLinkIcon color="black" /></span>
              </p>
            </a>
          </div>
          <div className="mx-auto max-w-[250px] text-center hover:scale-105 transition-all">
            <a href="https://fitsmallbusiness.com/livestream-shopping-statistics/" target="_blank" rel="noreferrer">
              <Counter start={0} end={60} />
              <p className="mt-2 text-preamble">av de som testat LiveShopping anser att deras köpupplevelse har förbättrats.
                <span className="inline-block ml-2 -mb-[2px]"><ExternalLinkIcon color="black" /></span>
              </p>
            </a>
          </div>
          <div className="mx-auto max-w-[250px] text-center hover:scale-105 transition-all">
            <a href="https://www.retailtouchpoints.com/topics/digital-commerce/livestreaming-update-coresight-debunks-5-common-myths" target="_blank" rel="noreferrer">
              <Counter start={0} end={40} />
              <p className="mt-2 text-preamble">färre returer jämfört med köp via traditionell e-handel.
                <span className="inline-block ml-2 -mb-[2px]"><ExternalLinkIcon color="black" /></span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BusinessNumbers);
