import React from 'react';

import ArrowRight from 'icons/ArrowRight';

type Props = {
  title: string;
  body: string;
  usp: string[];
  price: string;
  btn: React.ReactNode;
};

const PricePlanCard: React.FC<Props> = ({ title, body, usp, price, btn }) => {
  return (
    <>
      <div className="flex flex-col rounded-2xl bg-white px-10 py-11">
        <h2 className="heading-h2 mb-3">{title}</h2>
        <p className="opacity-50">{body}</p>
        <ul className="flex-1 space-y-3 py-4">
          {usp.map((usp, idx) => (
            <li key={idx} className="flex items-center">
              <ArrowRight className="mr-3" /><span className='w-full'>{usp}</span>
            </li>
          ))}
        </ul>
        <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-6 pt-7">
          <div className="text-right">
            <div className="font-bold">{price}</div>
            <div>Per månad</div>
          </div>
            {btn}
        </div>
      </div>
    </>
  );
};

export default PricePlanCard;
