import React, { useCallback, useEffect, useRef } from 'react';
import tw from '../../tailwind.config';

import type { About as IAbout } from 'types/About.interface';
import Link from "next/link";
import LogoIcon from "icons/LogoIcon";

const About: React.FC<IAbout> = () => {
  const aboutAnimationRef: any = useRef()

  const animate = useCallback(() => {
    document.querySelectorAll('.about-block').forEach(el => el.classList.add('animate'))
  }, [])

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        animate()
        observer.disconnect()
      }
    })
    observer.observe(aboutAnimationRef.current);
    return () => {
      observer.disconnect()
    }
  }, [animate])

  return (
    <div className="container mb-2 sm:md-14 md:mb-20 lg:px-16">
      <div className="mx-auto max-w-[1400px]">
        <div className="grid grid-cols-5 gap-4 md:gap-6">
          <div className="col-span-5 md:col-span-2">
            <h2 className="heading-h2 mb-4">Din plattform för all LiveShopping</h2>
            <p className="mb-5 md:mb-6 text-preamble">
              SHOWROOM är Sveriges nya samlingsplats för LiveShopping. Här möts tittare, e-handlare, varumärken och influencers – Live!
            </p>
            <Link href="/liveshopping">
              <button className="btn-black">Hur funkar det?</button>
            </Link>
          </div>
          <div ref={aboutAnimationRef} className="col-span-5 md:col-span-3 grid grid-cols-3 gap-4 md:gap-6">
            <div className="about-block col-span-5 md:col-span-1">
              <div className="flex items-center md:flex-col md:items-start pt-3">
                <div className="md: mb-5">
                  <LogoIcon
                    index={1}
                    iconColor={tw.theme.colors.green["500"]}
                    textColor={tw.theme.colors.green["500"]}
                  />
                </div>
                <div className="ml-5 md:ml-0">
                  <h4 className="heading-h4 mb-0 md:mb-2">LiveShopping?</h4>
                  <p className="opacity-70">Handla alla produkter som visas i livevideon.</p>
                </div>
              </div>
            </div>
            <div className="about-block col-span-5 md:col-span-1">
              <div className="flex items-center md:flex-col md:items-start pt-3">
                <div className="md:mb-5">
                  <LogoIcon
                    index={2}
                    iconColor={tw.theme.colors.rose["500"]}
                    textColor={tw.theme.colors.rose["500"]}
                  />
                </div>
                <div className="ml-5 md:ml-0">
                  <h4 className="heading-h4 mb-0 md:mb-2">Interagera</h4>
                  <p className="opacity-70">Ställ frågor om en produkt direkt till programledaren.</p>
                </div>
              </div>
            </div>
            <div className="about-block col-span-5 md:col-span-1">
              <div className="flex items-center md:flex-col md:items-start pt-3">
                <div className="md:mb-5">
                  <LogoIcon
                    index={3}
                    iconColor={tw.theme.colors.blue["500"]}
                    textColor={tw.theme.colors.blue["500"]}
                  />
                </div>
                <div className="ml-5 md:ml-0">
                  <h4 className="heading-h4 mb-0 md:mb-2">Allt samlat</h4>
                  <p className="opacity-70">Alla LiveShows från dina brands på samma plattform. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(About);
