import React from 'react'
import Collapse from '../components/Collapse'
import { FaqList as IFaqList } from '../types/Faq.interface'

const FaqList: React.FC<IFaqList> = ({ title, faqs }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-xl">
        <h2 className="heading-h2 text-center mb-10">{title}</h2>
        {
          faqs.map(({ question, answer }, idx) => (
            <Collapse key={idx} title={question} content={answer} />
          ))
        }
      </div>
    </div>
  )
}

export default FaqList