import React from 'react';
import Link from 'next/link';
import { RssItem as IRssItem } from 'types/RssFeed.interface';

const RssRow: React.FC<IRssItem> = ({ date, title, description, image, url }) => {

  return (
    <Link href={url} passHref>
      <a className="cursor-pointer" target="_blank" rel="noreferrer" aria-label="link to article">
        <div className="flex flex-row items-center justify-between border-b-[1px] border-b-black border-opacity-10 pt-6 pb-10">
          <div>
            <p className="text-small opacity-50">{date}</p>
            <h4 className="heading-h4">{title}</h4>
            <p>{description}</p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default RssRow;
