import React, { useState, useMemo } from 'react';
import { splittList, scrollToId } from 'utils';
import Pagination from '../components/Pagination'
import type { ShowList as IShowList } from 'types/ShowList.interface';
import ShowCard, { ShowCardPlace } from 'components/ShowCard';
import { useProfile } from "context/UserContext";

const ShowGrid: React.FC<IShowList> = ({ content, name }) => {
  const { state } = useProfile()
  const isFavourites = name === "favourite-show-list"

  let showsList = content.shows;

  if (isFavourites) {
    showsList = state?.favourites?.shows ?? []
  }

  const [currentPage, setCurrentPage] = useState(1);

  if (!showsList?.length) return null

  const splittedArticles = splittList(showsList, 15)
  return (
    <div id="show-grid-list" className="scroll-mt-5 container my-8 sm:my-14 md:my-20 lg:px-16">
      <div className="mx-auto max-w-7xl">
        <div className="mb-2 flex items-center justify-between">
          <h3 className="heading-h3 pb-4">{content?.title}</h3>
        </div>

        <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {splittedArticles[currentPage - 1]?.map((show, idx) => (
            <div className="w-full" key={idx}>
              <ShowCard place={ShowCardPlace.Grid} show={show} />
            </div>
          ))}
        </div>
        <Pagination
          scrollTo={() => scrollToId('#show-grid-list')}
          totalPageCount={splittedArticles.length}
          currentPage={currentPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default React.memo(ShowGrid);
