import React from 'react'

interface IProps {
  items: Tags[];
  full_width?: boolean;
}

interface Tags {
  tag: string;
  text: string;
}

const TextSection: React.FC<IProps> = ({ items, full_width }) => {

  const getClass = (tag: string) => {
    switch (tag) {
      case 'h1':
        return 'heading-h1'
      case 'h2':
        return 'heading-h2'
      case 'h3':
        return 'heading-h3'
      case 'h4':
        return 'heading-h4'
      default:
        break;
    }
  }
  return <div id="text-parent" className={"container max-w-[800px] mx-auto mb-14 md:mb-20 " + (full_width ? 'max-w-4xl md:px-0' : 'max-w-[650px]')}>
    {items.map(({ tag, text }, i) => <div key={i} dangerouslySetInnerHTML={{ __html: `<${tag} class="${getClass(tag)}">${text}</${tag}>` }} />)}
  </div>
}

export default TextSection