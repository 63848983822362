import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Category } from 'types/Category.interface';
import { getAspectRatioCSS } from "utils";

type Props = {
  category: Category;
  noImage?: boolean;
};

const CategoryCard: React.FC<Props> = ({ category, noImage = false }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const url = category.url ?? '/';

  let startPosX = 0;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, url: String) => {
    if(isDragging) {
      e.preventDefault();
    }
    setIsMouseDown(false);
    setIsDragging(false);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setIsMouseDown(true);
    startPosX = e.clientX;
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if(isDragging || !isMouseDown)
      return;
    if(e.clientX !== startPosX) {
      setIsDragging(true);
    }
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setIsDragging(false);
  };

  return (
    <Link href={url}>
      <a
        onClick={(e) => handleClick(e, url)}
        onMouseDown={(e) => handleMouseDown(e)}
        onMouseMove={(e) => handleMouseMove(e)}
        onMouseOut={(e) => handleMouseOut(e)}
        className={`undraggable relative block aspect-square ${getAspectRatioCSS(1, 1)} w-full overflow-hidden rounded-full transition-all hover:scale-105`}
        style={{ backgroundColor: category.color ?? '#ffffff' }}>
        <div className="absolute z-10 flex h-full w-full flex-col items-center justify-center px-2">
          <h4 className={`heading-h4  max-w-[160px] text-center text-white ${noImage ? 'lg:text-medium md:max-w-[210px]' : ''}`}>{category.name}</h4>
          <p className={`text-button text-white ${noImage ? 'lg:text-base' : ''} ${category.show_count == '0' ? 'hidden' : ''}`}>{category.show_count} Shows</p>
        </div>
        {
          (typeof category?.image === 'string') && !noImage
            ? <Image
              src={category?.image}
              alt=""
              loading="lazy"
              layout="fill"
              objectPosition="center"
              objectFit="cover"
              quality={100}
            />
            : null
        }
      </a>
    </Link>
  );
};

export default React.memo(CategoryCard);
