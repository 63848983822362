import React from 'react';

import Carousel from 'components/Carousel';
import ProductCard from 'components/ProductCard';
import { ProductList as IProductList } from 'types/Products.interface';

const ProductList: React.FC<IProductList> = ({ products }) => {
  return (products.length === 0 ? null :
    <div className="container mb-8 sm:mb-14 md:mb-20 lg:px-16 products-list">
      <div className="mx-auto max-w-7xl">
        <Carousel title={'Produkter'}>
          {products.map((product) => (
            <div key={product.id} className="w-full p-3">
              <ProductCard {...product} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default React.memo(ProductList);
