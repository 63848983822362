import Image from 'next/image';
import React from 'react';

interface IProps {
  name: string;
  title: string;
  description: string;
  logo: string;
  photo: string;
}

const TestimonialCard = ({ name, title, description, logo, photo }: IProps) => {
  return (
    <div className="px-3 md:max-w-[836px]">
      <div className="h-auto max-w-[120px]">
        <Image src={logo} alt="" layout="intrinsic" width={120} height={120} />
      </div>
      <h2 className="heading-h2 mb-10">
        {description}
      </h2>
      <div className="flex flex-row flex-wrap items-center justify-between">
        <div className="flex flex-row">
          <div className="rounded-full">
            <Image
              src={photo}
              className=""
              layout="intrinsic"
              alt=""
              width={60}
              height={60}
              quality={100}
            />
          </div>
          <div className="ml-4">
            <p className="font-sans text-base font-semibold">{name}</p>
            <p className="font-sans text-base">{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialCard);
