import React from 'react';

type Props = {
  category: string;
  color?: string;
  textCssClass?: 'text-white' | 'text-black';
};

const CategoryTag: React.FC<Props> = ({ category, color = 'black', textCssClass = 'text-black' }) => {
  return (
    <>
      <div className="my-0.5 hidden rounded-3xl px-3 text-small font-extralight text-white sm:inline-block" style={{ backgroundColor: color}}>
        {category}
      </div>
      <div className="inline-block text-small font-extralight text-black sm:hidden">
        <span className="mr-2 inline-block h-2 w-2 rounded-full" style={{ backgroundColor: color}} />
        <span className={textCssClass}>{category}</span>
      </div>
    </>
  );
};

export default CategoryTag;
