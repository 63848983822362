import React from 'react';
import Link from 'next/link'
import Dialog from 'components/Dialog';
import PricePlanCard from 'components/PricePlanCard';

export interface IPricePlan {
  template: string;
  name: string;
}

const contactUsDialog = (src: string) => {
  return (
    <Dialog
      button={<button className="btn-white border-2">Kontakta oss</button>}>
      <div className="flex justify-center w-full mt-20">
        <iframe
          src={src}
          width="360"
          height="381"
          style={{ border: 0 }}></iframe>
      </div>
    </Dialog>
  )
}

const Pricing: React.FC<IPricePlan> = ({ template }) => {
  return (
    <div id={template.toLowerCase()} className="px-4 md:px-6 lg:px-8 bg-green-300">
      <div className="mx-auto max-w-[1400px]">
        <div className="flex flex-col items-center justify-center py-16">
          <h1 className="heading-h2 mb-7 ">Kom igång</h1>
          <div className="grid gap-6 pb-12 md:grid-cols-3">
            <PricePlanCard
              title="Just show up!"
              body="För dig som vill testa och lära känna Showroom. Skapa gratis konto."
              usp={['Utforska funktioner', 'Låt dig inspireras']}
              price="0 kr"
              btn={
                <Link href={process.env.NEXT_PUBLIC_CMS_DOMAIN + "/signup"}>
                  <button className="btn-black" >Skapa konto</button>
                </Link>
              }
            />
            <PricePlanCard
              title="Showtime!"
              body="För dig som är redo."
              usp={['Lägga upp och schemalägga obegränsat antal LiveShows', 'Anpassa ditt Showroom efter ditt varumärkes profil', 'Få överblick över dina tittare']}
              price="2000 kr"
              btn={contactUsDialog("https://pages.upsales.com/14783u40330cb357c0401699662d91802072e0-frame")}
            />
            <PricePlanCard
              title="Run the show!"
              body="För dig som vill ta dina LiveShows till nästa nivå."
              usp={['Allt som ingår i Showtime!', 'Benchmarking - jämför resultat av dina LiveShows med shows av andra varumärken inom samma kategori', 'Få överblick över marknadsföringskanaler och kampanjer som har levererat flest engagerade tittare']}
              price="4000 kr"
              btn={contactUsDialog("https://pages.upsales.com/14783u70811167f9434952ab20545eb79cd1c7-frame")}
            />
          </div>
          <h3 className="heading-h3 text-center mb-4">Vill du veta mer om hur det fungerar?</h3>
          <Link href="/foretag/vanliga-fragor-och-svar-foretag">
            <a className="btn-black">Frågor & svar</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Pricing);
