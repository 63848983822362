import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import type { Brand } from 'types/Brands.interface';
import Dialog from 'components/Dialog';
import SignupForm from 'components/SignupForm';
import LoginForm from 'components/LoginForm';
import { useProfile } from 'context/UserContext';
import axios from 'axios';
import Plus from 'icons/Plus';
import Check from 'icons/Check';
import Spinner from 'icons/Spinner';

type Props = {
  gradient?: boolean;
  brand: Brand;
};

const BrandHero: React.FC<Props> = ({ gradient = false, brand, ...props }) => {
  const { state } = useProfile();
  const profileDispatch = useProfile().dispatch;
  const { image, image_portrait, name, short_description, url } = brand;
  const favouriteBrands = state?.favourites?.brands ?? [];
  const isFavourite = !!favouriteBrands.find((o) => o.id === brand.id);
  const isOriginals = url === '/showrooms/oob';
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookmarkClicked, setBookmarkClicked] = useState(false);
  const [isPortrait, setIsPortrait] = useState<boolean>();
  const updateByOrientation = useCallback(() => {
    const currentIsPortrait = window.innerHeight > window.innerWidth;
    if (isPortrait !== undefined && ((isPortrait && currentIsPortrait) || (!isPortrait && !currentIsPortrait))) return;

    setIsPortrait(currentIsPortrait);
  }, [isPortrait]);

  const toggleFavouriteBrand = async () => {
    setIsLoading(true);
    const res = await axios.post(`/api/auth/favourites/brands/${brand.id}`);
    if (!isFavourite) {
      window.IMP_HL?.followBrandName(brand.name);
    }
    if (res?.data?.favourites?.brands) {
      setIsLoading(false);
      profileDispatch({ type: 'update_favourite_brands', brands: res?.data?.favourites?.brands });
    } else {
      setIsLoading(false);
      profileDispatch({ type: 'update_favourite_brands' });
    }
  };

  useEffect(() => {
    updateByOrientation();
    window.addEventListener('resize', updateByOrientation);
    return () => {
      window.removeEventListener('resize', updateByOrientation);
    };
  }, [updateByOrientation]);

  return (
    <div className="mb-6 sm:mb-20">
      <div
        className="relative bg-cover bg-center bg-no-repeat object-fill object-center"
        style={{
          backgroundImage:
            'linear-gradient(5deg, rgba(0, 0, 0, 0.5), 20%, rgba(0, 0, 0, 0)),url("' +
            (isPortrait && image_portrait && image_portrait.length ? image_portrait : image) +
            '")',
        }}>
        <div className="container">
          <div className="mx-auto max-w-[1400px]">
            <div className="flex min-h-[580px] flex-col items-start justify-end p-10">
              <div className="max-w-2xl">
                <h1 className="heading-h3 sm:heading-h1 mb-4 break-words text-white">{name}</h1>
                <p className="mb-4 text-preamble text-white">{short_description}</p>
                <Dialog
                  title={showLogin ? 'Logga in' : 'Skapa konto'}
                  button={
                    <button
                      title={isFavourite ? 'Följer ✓' : 'Följ varumärke'}
                      onClick={toggleFavouriteBrand}
                      onMouseLeave={() => {
                        setBookmarkClicked(false);
                      }}
                      className={`btn-white-icon btn-icon-md ${
                        isFavourite ? '' : 'hover:w-24'
                      } justify-start overflow-hidden transition-all hover:${
                        bookmarkClicked ? 'text-black' : 'text-rose-400'
                      } ${!isFavourite ? 'text-opacity-20' : 'text-rose-400'}`}>
                      <div className="mx-[9px] flex">
                        {isLoading ? (
                          <Spinner size={'1.6rem'} color="#999" />
                        ) : isFavourite ? (
                          <Check size={'1.6rem'} />
                        ) : (
                          <Plus size={'1.6rem'} />
                        )}
                        <div className="mx-2 text-base">Följ</div>
                      </div>
                    </button>
                  }>
                  {showLogin ? (
                    <LoginForm setShowSignUp={() => setShowLogin(false)} favorite={`brands/${brand.id}`} />
                  ) : (
                    <SignupForm setShowLogin={() => setShowLogin(true)} favorite={`brands/${brand.id}`} />
                  )}
                </Dialog>
              </div>
            </div>
          </div>
          {isOriginals && (
            <div className="absolute right-0 bottom-0 mb-10 ml-32 mr-10 sm:left-1/2 sm:right-auto sm:mx-0 sm:-translate-x-1/2 sm:transform">
              <Image
                className="max-w-[200px] md:max-w-[250px]"
                src="/assets/Showroom_Logotype_White_Originals.svg"
                alt="Showroom Originals"
                width="100%"
                layout="fill"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandHero);
