import React from 'react';

import type { Brands as IBrandsGrid } from 'types/Brands.interface';
import BrandCard from 'components/BrandCard';

const BrandsGrid: React.FC<IBrandsGrid> = ({ brands }) => {
  if(!brands?.length) return null

  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-7xl">
        <h3 className="heading-h3 mb-4 text-left md:mb-6">Varumärken</h3>
        <div className="grid grid-cols-2 place-items-center gap-6 sm:grid-cols-3 md:grid-cols-4">
          {brands?.map((brand, idx) => <BrandCard {...brand} key={idx}/>)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandsGrid);
