import React from 'react';
import ReactPlayer from 'react-player'
import { SimpleVideo as ISimpleVideo } from 'types/SimpleVideo.interface';

const SimpleVideo: React.FC<ISimpleVideo> = ({ video_url, video_settings }) => {
  const onErrorHandle = (event: any) => {
    event?.target?.closest('.container').classList.add('hidden');
  }

  return <div className="container mb-8 sm:mb-14 md:mb-20">
    <div
        className={`relative flex justify-center items-center aspect-[16/9] max-w-[939px] mx-auto rounded-3xl overflow-hidden`}
        style={{backgroundColor: '#f3eeee'}}>
      <ReactPlayer
          playing={!!video_settings.playing}
          controls={!video_settings.hideControls}
          loop={!!video_settings.loop}
          muted={!!video_settings.muted}
          playsinline={true}
          width='100%'
          height='100%'
          url={video_url}
          onError={onErrorHandle}
      />
    </div>
  </div>
};

export default React.memo(SimpleVideo);
