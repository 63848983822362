import React, { Dispatch, HTMLInputTypeAttribute, SetStateAction } from 'react';

type Props = {
  id: string;
  label: string;
  type: HTMLInputTypeAttribute;
  value: string;
  set: Dispatch<SetStateAction<string>>;
  border?: boolean;
  placeholder?: string;
  hideLabel?: boolean;
  error?: boolean;
};

const TextInput: React.FC<Props> = ({ id, label, type, value, placeholder = '', hideLabel, border, set, error = false}) => {
  return (
    <>
      <div className="relative w-full">
        {hideLabel ?? (
          <label htmlFor={id} className="mb-2 block font-semibold">
            {label}
          </label>
        )}
        <input
          type={type}
          id={id}
          className={`peer h-[60px] w-full rounded-full bg-white px-7 text-black outline-none ring-inset ${error ? 'ring-2  ring-red' : (!!border ? 'ring-1  ring-black' : '')}`}
          placeholder={placeholder}
          value={value}
          onChange={(event) => set(event.target.value)}
        />
      </div>
    </>
  );
};

export default TextInput;
