import React from 'react'
import { RawHTML as IRawHTML } from 'types/RawHTML.interface';
import Script from "next/script";

const RawHTML: React.FC<IRawHTML> = ({ raw_html }) => {
  const scriptStrings = raw_html.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi)
  const scripts: any = [];

  scriptStrings?.forEach((scriptString) => {
    raw_html = raw_html.replace(scriptString, '');
    scripts.push(scriptString.replace('<script>', '').replace('</script>', ''));
  })

  return <>
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div
          className="mx-auto max-w-[1260px]"
          dangerouslySetInnerHTML={{__html: raw_html}}
      />
    </div>
    {scripts.map((scriptText: string, index: number) =>
        <Script id={`raw-script-${index}`} key={index} dangerouslySetInnerHTML={{__html: scriptText}} />
    )}
  </>

}

export default React.memo(RawHTML);
