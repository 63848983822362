import React from 'react'
import RssRow from '../components/RssRow'

import { RssFeed as IRssFeed } from 'types/RssFeed.interface'

const RssFeed: React.FC<IRssFeed> = ({ items }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-2xl">
        {items.map( (item, idx) => <RssRow key={idx} {...item} />)}
      </div>
    </div>
  )
}

export default RssFeed