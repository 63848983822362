import ChevronLeft from 'icons/ChevronLeft';
import ChevronRight from 'icons/ChevronRight';
import React from 'react';
import { usePagination, DOTS } from '../utils/hooks'

type Props = { 
  totalPageCount: number,
  scrollTo?: () => void,
  onPageChange: (page: number) => void,
  currentPage: number,
  siblingCount?: number,
  pageSize?: number
}

const Pagination: React.FC<Props> = ({ totalPageCount, scrollTo, onPageChange, currentPage, siblingCount = 1, pageSize = 12 }) => {
  const paginationRange = usePagination({ totalPageCount, currentPage, siblingCount, pageSize }) || [];
  if(totalPageCount < 2) return null
  
  const onChangePageNumber = (page: number) => {
    onPageChange(page)
    if(scrollTo) scrollTo()
  }

  const onNext = () => { onChangePageNumber(currentPage + 1) }
  const onPrevious = () => { onChangePageNumber(currentPage - 1) }
  
  let isFirstPage = currentPage === 1
  let isLastPage = currentPage === paginationRange[paginationRange.length - 1]

  return (
    <div className="my-16 flex flex-nowrap items-center justify-center">
      <button className={`btn-text ${isFirstPage ? 'opacity-50' : ''}`} onClick={onPrevious} disabled={isFirstPage} aria-label="Föregående sida">
        <ChevronLeft size="1rem" />
        <span className="hidden sm:block">Föregående</span>
      </button>
      <ul className="grid grid-flow-col gap-1">
        {paginationRange.map((pageNumber, idx) => {
          if (pageNumber === DOTS || typeof(pageNumber) === 'string') {
            return <li key={idx} className='mt-[3px]'>{DOTS}</li>;
          }
          return (
            <li key={idx} onClick={() => onChangePageNumber(pageNumber)} >
              <button className={pageNumber === currentPage ? 'btn-black-icon' : 'btn-icon'}>{pageNumber}</button>
            </li>
          );
        })}
      </ul>
      <button className={`btn-text ${isLastPage ? 'opacity-50' : ''}`} onClick={onNext} disabled={isLastPage} aria-label="Nästa sida">
        <span className="hidden sm:block">Nästa</span>
        <ChevronRight size="1rem" />
      </button>
    </div>
  );
};

export default Pagination;
