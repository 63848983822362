export enum Provider {
  bambuser = 'bambuser',
  streamify = 'streamify',
  vimeo = 'vimeo',
  zellma = 'zellma',
  spockee = 'spockee',
}

export interface Show {
  id: string;
  url: string;
  title: string;
  image: string;
  image_small: string | null;
  brand: Brand;
  category: Category;
  categories: Category[] | null;
  live_at: string;
  duration: number;
  provider: Provider;
  provider_id: string;
}

interface Brand {
  id: string;
  name: string;
  slug: string;
  logo: string;
  logo_white: string;
  image: string;
  url: string;
}

interface Category {
  name: string;
  show_count: number;
  color: string;
  url: string;
  image: string;
}
