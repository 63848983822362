import React from 'react';
import ReactElasticCarousel from 'react-elastic-carousel';
import TestimonialCard from 'components/TestimonialCard';

export interface IProps {}

export interface DotsProps {
  pages: Array<number>;
  activePage: number;
  onClick: Function;
}

const Dots = ({ pages, activePage, onClick }: DotsProps) => {
  return (
    <div className="mt-6 flex flex-row">
      {pages.map((page, idx) => {
        const active = activePage === page;
        return (
          <button
            key={idx}
            onClick={() => onClick(page)}
            className="mx-2 h-2 w-2 rounded-full border-[1px]"
            style={{ backgroundColor: active ? 'black' : '' }}
          />
        );
      })}
    </div>
  );
};

const Testimonial: React.FC<IProps> = () => {
  return (
    <div className="mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-7xl bg-teal-100">
        <div className="container overflow-hidden py-16 px-0">
          <ReactElasticCarousel
            isRTL={false}
            enableAutoPlay
            itemPadding={[0, 0, 0, 0]}
            autoPlaySpeed={10000}
            itemsToShow={1}
            showArrows={false}
            renderPagination={(props) => <Dots {...props} />}>
            <TestimonialCard
              description="För oss på Kjell & Co är LiveShopping en lönsam och viktig kanal. Sedan starten 2020 har vår tittarskara växt till flera tusen tittare! Det finns flera anledningar till detta och en av dem är Showroom. Showroom har även blivit en viktigt partner i utvecklingen av vår LiveShopping." 
              name="Nicholas Balint"
              title="Head of Live Commerce"
              logo="/assets/kjell.svg"
              photo="/assets/person.jpg"
            />
            <TestimonialCard
              description="Vi på Blomsterlandet ser LiveShopping som ett digital skyltfönster.
              Genom att finnas på Showroom har vi nått nya målgrupper och kan på ett enkelt sätt kommunicera med ett stort antal konsumenter samtidigt!"
              name="Erica Dahlqvist"
              title="Marknadskoordinator / LiveShopping"
              logo="/assets/blomsterlandet.svg"
              photo="/assets/person2.jpeg"
            />
          </ReactElasticCarousel>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Testimonial);
