import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import type { Brands as IBrands } from 'types/Brands.interface';

const BrandsCompact: React.FC<IBrands> = ({ brands }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-7xl">
        <h3 className="heading-h3 pb-4 sm:pb-8 text-center">Showrooms</h3>
        <div className="grid grid-cols-2 place-items-center gap-0 sm:gap-y-0 sm:gap-x-6 sm:grid-cols-3 md:grid-cols-5">
          {brands?.map((brand, idx) => (
            <Link href={brand.url} key={idx}>
              <a className="relative h-[120px] sm:h-[200px] w-full transition-all hover:scale-105" aria-label="brand logotype">
                { typeof brand?.logo === "string" 
                  ? <Image src={brand?.logo} alt="" className="scale-75 sm:scale-90" layout="fill" objectFit="contain" quality={100} />
                  : <div className="w-full h-full text-center py-20">{brand.name}</div>
                }
              </a>
            </Link>
          ))}
          <div className="col-span-2 sm:col-span-3 md:col-span-5">
            <Link href="/showrooms">
              <a className="btn-black mt-3 md:mt-6">Visa alla</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandsCompact);
