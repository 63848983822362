import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import type { Brand as IBrand } from 'types/Brands.interface';
import CategoryTag from './CategoryTag';

const BrandCard: React.FC<IBrand> = ({ url, logo, name, categories }) => {
  return (
    <Link href={url}>
      <a className="w-full ">
        <div
          className="relative aspect-[15/11] mb-4 rounded-xl bg-white p-8 transition-all hover:scale-105"
        >
          {
          typeof logo === "string" && <Image
              src={logo}
              alt={name}
              layout="fill"
              objectFit="contain"
              loading="lazy"
              className="scale-[60%]"
              quality={100}
            />
          }
        </div>
        <h4 className="heading-h4 mb-2 truncate text-center">{name}</h4>
        {categories &&
          <div className="flex flex-wrap items-center justify-center space-x-1 pb-2 sm:pb-4 md:pb-6">
            {categories?.map((category, idx) => (
              <CategoryTag category={category.name} color={category.color} key={idx} />
            ))}
          </div>
        }

      </a>
    </Link>
  );
};

export default React.memo(BrandCard);
