import React from 'react';
import tw from '../../tailwind.config';

type Props = {
  index?: number,
  iconColor?: string,
  textColor?: string,
};

const WIDTH = 50;

const LogoIcon: React.FC<Props> = ({ index = 0, iconColor = tw.theme.colors.black, textColor = tw.theme.colors.black }) => {
  return (
    <div className="relative w-12">
      <div>
        <svg width={WIDTH} viewBox="0 0 37 44" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7114 11.7114C15.8964 7.5265 22.6945 7.51107 26.9004 11.6622L32.957 5.6057C25.4118 -1.88485 13.1797 -1.86879 5.65543 5.65543C-1.86879 13.1797 -1.88485 25.4118 5.6057 32.957L11.6622 26.9006C7.51108 22.6945 7.5265 15.8964 11.7114 11.7114Z" />
          <path d="M30.5823 37.7187C38.1234 30.1776 38.1234 17.9071 30.5823 10.366C30.5654 10.3491 30.5481 10.333 30.531 10.3162L24.4747 16.3728C24.4915 16.3897 24.5094 16.4051 24.5262 16.422C28.7283 20.6239 28.7283 27.4609 24.5262 31.6627C20.3244 35.8647 13.4875 35.8647 9.28549 31.6627C9.2686 31.6458 9.25318 31.6279 9.2363 31.6111L3.17969 37.6675C3.19657 37.6845 3.21263 37.7018 3.22951 37.7187C10.7706 45.2599 23.0411 45.2599 30.5823 37.7187Z" />
        </svg>
      </div>
      <p className={`absolute text-center top-[16px] font-bold`} style={{color: textColor, width: WIDTH + "px"}}>{index}</p>
    </div>
  );
};

export default LogoIcon;