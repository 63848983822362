import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { Brand } from 'types/Brands.interface';

import ArrowRight from 'icons/ArrowRight';
import Instagram from 'icons/Instagram';
import Facebook from 'icons/Facebook';
import Youtube from 'icons/Youtube';
import TikTok from 'icons/TikTok';
import Twitter from 'icons/Twitter';

interface IBrandAbout {
  brand: Brand;
}

const RoundBtn: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-black transition-all hover:scale-110 hover:drop-shadow-lg">
    {children}
  </div>
);

const BrandAbout: React.FC<IBrandAbout> = ({ brand }) => {
  const isBrandPath = useRouter().asPath === brand.url;

  const { logo, long_description, external_url, url, social } = brand;

  return (
    <div className="container">
      <div className="grid gap-6 pb-10 pt-2 sm:pt-0 sm:pb-22 md:grid-cols-3 md:px-22 md:pt-2">
        <div className="flex flex-col">
          <div className="w-[249px]">
            {logo && (
              <Image
                src={logo}
                alt=""
                objectPosition="left top"
                className="object-contain"
                layout="intrinsic"
                width={249}
                height={68}
                loading="lazy"
                quality={100}
              />
            )}
          </div>
          {isBrandPath ? (
            <>
              <a
                href={external_url?.url}
                target="_blank"
                rel="noreferrer"
                className="text-link my-4 flex flex-row items-center font-medium">
                {external_url?.label}
              </a>
              <div className="flex space-x-4">
                {social?.facebook && (
                  <a href={social.facebook} target="_blank" rel="noreferrer">
                    <RoundBtn>
                      <Facebook fill="white" />
                    </RoundBtn>
                  </a>
                )}
                {social?.instagram && (
                  <a href={social.instagram} target="_blank" rel="noreferrer">
                    <RoundBtn>
                      <Instagram fill="white" />
                    </RoundBtn>
                  </a>
                )}
                {social?.youtube && (
                  <a href={social.youtube} target="_blank" rel="noreferrer">
                    <RoundBtn>
                      <Youtube fill="white" />
                    </RoundBtn>
                  </a>
                )}
                {social?.tiktok && (
                  <a href={social.tiktok} target="_blank" rel="noreferrer">
                    <RoundBtn>
                      <TikTok fill="white" />
                    </RoundBtn>
                  </a>
                )}
                {social?.twitter && (
                  <a href={social.twitter} target="_blank" rel="noreferrer">
                    <RoundBtn>
                      <Twitter fill="white" />
                    </RoundBtn>
                  </a>
                )}
              </div>
            </>
          ) : (
            <a href={url} rel="noreferrer" className="mt-2 flex flex-row items-center font-medium">
              Till varumärkessida
              <ArrowRight className="m-1 scale-75" />
            </a>
          )}
        </div>
        <div className="col-span-2 text-preamble" dangerouslySetInnerHTML={{ __html: `${long_description}` }} />
      </div>
    </div>
  );
};

export default React.memo(BrandAbout);
