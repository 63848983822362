// import { theme } from '../../tailwind.config';
import { ShowStatus } from '../components/ShowCard';

export const getThemeColor = (color: string, opacity?: number): string => {
  // return opacity ? theme?.colors?.[color]?.[opacity] : theme.colors?.[color]
  return '';
};

export const getShowCardStatus = (liveAt: string, duration: number): ShowStatus => {
  const dateNow = new Date().getTime() / 1000;
  const liveAtTimestamp = new Date(liveAt.replace(/-/g, '/')).getTime() / 1000;

  if (dateNow > liveAtTimestamp && dateNow < liveAtTimestamp + duration) {
    return ShowStatus.Live;
  } else if (dateNow < liveAtTimestamp) {
    return ShowStatus.Upcoming;
  } else if (dateNow > liveAtTimestamp + duration) {
    return ShowStatus.Ondemand;
  }
  return ShowStatus.Ondemand;
};


export const splittList = (list: any[], pageSize: number = 12): any[][] => {
  return list.reduce((all: any[][], obj, i) => {
    const chunkIndex = Math.floor(i/pageSize);
    all[chunkIndex] = [...all[chunkIndex] || [], obj]
    return all
  }, [])
}

export const scrollToId = (id: string) => {
  document?.querySelector(id)?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

export const getAspectRatioCSS = (w:number, h:number) => {
  const p = Math.round((h / w) * 100);
  return "before:float-left before:pt-[" + p + "%] before:content-[''] after:block after:content-[''] after:clear-both";
};
