import React from 'react';
import Image from 'next/image';

interface IImage {
  name: string;
  template: string;
  image: string;
}

const ImageSection: React.FC<IImage> = ({ image }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <Image src={image} alt="" layout="fill" style={{ maxWidth: '800px', margin: '0 auto' }} />
    </div>
  );
};

export default React.memo(ImageSection);
