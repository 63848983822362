import React from 'react';

import FeaturedShow from './FeaturedShow';
import CategoryTag from 'components/CategoryTag';
import { getShowCardStatus } from 'utils';
import { ShowStatus } from 'components/ShowCard';
import Link from 'next/link';
import { ShowHero as IShowHero } from 'types/ShowHero.interface';

const ShowHero: React.FC<IShowHero> = ({ title, short_description, long_description, show, logo, logo_white, read_more, brand }) => {
  const { categories, category } = show;
  const status = getShowCardStatus(show.live_at, show.duration);
  const isLive = status === ShowStatus.Live;

  const categoryList = categories
    ? categories
    : category ? [category] : [];

  return (
    <>
      <FeaturedShow
        show={show}
        title={title}
        read_more={read_more}
        logo={isLive ? logo_white : logo}
        short_description={short_description}
        long_description={''}
        name="FeaturedShow"
        template="FeaturedShow"
      />
      <div className="mb-16 md:mb-24 md:-mt-24">
        <div className={`px-8 py-10 ${isLive ? 'bg-black text-white' : 'bg-lightestGray'}`}>
          <div className="mx-auto mb-12 max-w-3xl space-y-4 text-preamble">
            <div dangerouslySetInnerHTML={{ __html: long_description }} />
          </div>
          <div className="mx-auto max-w-3xl border-t-[1px] border-t-black pt-6 pb-16">
            {
              categoryList
              &&
              categoryList.map(category => (
                <Link href={category.url} key={category.name}>
                  <a className="hover:drop-shadow-lg mr-2">
                    <CategoryTag category={category.name} color={category.color} />
                  </a>
                </Link>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ShowHero);
