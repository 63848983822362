import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const Symbol: React.FC<Props> = ({ color = '#D6B3B4', className }) => {
  return (
    <>
      <svg
        className={className}
        width="197"
        height="174"
        viewBox="0 0 197 174"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.474 21.9271C104.575 -13.779 36.556 -3.8593 0.84986 44.0397C0.769922 44.1469 0.69491 44.256 0.61512 44.3643L39.0848 73.04C39.1647 72.9327 39.2357 72.8211 39.3157 72.7144C59.2114 46.0241 97.1107 40.497 123.799 60.3934C150.489 80.2882 156.016 118.187 136.12 144.877C136.041 144.984 135.954 145.084 135.874 145.192L174.343 173.869C174.424 173.762 174.506 173.659 174.586 173.551C210.293 125.652 200.373 57.6337 152.474 21.9271Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default Symbol;
