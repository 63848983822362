import React from 'react';

import MultiSelect from 'components/MultiSelect';
import { Category } from 'types/Category.interface';

type Props = {
  categories: Category[];
  title: string;
  setSelectedCategories: Function;
};

const BrandHero: React.FC<Props> = ({ categories, title, setSelectedCategories }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="bg-teal-100">
        <div className="flex flex-col items-center justify-center p-5 sm:min-h-[400px] sm:p-16">
          <div className="max-w-[829px] text-center">
            <h1 className="heading-h1 mb-4">{title}</h1>
  
            <div className="mx-auto mt-8 w-72">
              <MultiSelect categories={categories} setSelectedCategories={setSelectedCategories}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandHero);
