import React, { useState } from 'react';
import TextInput from 'components/TextInput'
import SubmitButton from 'components/SubmitButton';
import axios from 'axios';
import Dialog from 'components/Dialog'
import ForgotPasswordForm from 'components/ForgotPasswordForm'
import Router from "next/router";

type Props = {
  setShowSignUp?: () => void,
  setOpen?: (open: boolean) => void
  favorite?: string
};

const LoginForm: React.FC<Props> = ({ setShowSignUp, setOpen, favorite }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setValid(true);
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      if(response?.data?.user?.id && !!favorite) {
        await axios.post(`/api/auth/favourites/${favorite}`)
      }
      if(setOpen) setOpen(false)
      window.location.href = Router.asPath;
    } catch (error) {
      setLoading(false);
      setValid(false);
      console.log(error);
    }
  };

  return (
    <form id="loginForm" onSubmit={(e) => handleOnSubmit(e)}>
      {valid ? '' :
        <div className="mb-3">Fel e-postadress och/eller lösenord.</div>
      }
      <div className="relative w-full px-10 pt-0 pb-6">
        <div className="mb-5">
          <TextInput
            border
            hideLabel
            id="email"
            type="email"
            label="Din e-postadress"
            placeholder="Din e-postadress"
            value={email}
            error={!valid}
            set={setEmail}
          />
        </div>
        <div className="mb-5">
          <TextInput
            border
            hideLabel
            id="password"
            type="password"
            label="Ditt lösenord"
            placeholder="Ditt lösenord"
            value={password}
            error={!valid}
            set={setPassword}
          />
        </div>
        <div className="flex flex-col sm:flex-row items-start sm:justify-between">
          <SubmitButton label="Logga in" loading={loading} />
          <Dialog
            maxWidth="4xl"
            button={
              <button type="button" className="btn-text btn-large pl-0">
                Glömt ditt lösenord?
              </button>
            }>
              <div className="flex flex-col items-center">
                <div className="max-w-4xl mt-20 mb-28 mx-16 xs:mx-24 sm:mx-40">
                  <h2 className="heading-h2">Glömt ditt lösenord?</h2>
                  <ForgotPasswordForm />
                </div>
              </div>
          </Dialog>
        </div>
      </div>
      <div className="w-full bg-black p-6 rounded-b-2xl flex flex-col xs:flex-row justify-center">
        <p className="text-white">Har du inget konto?</p>
        <button id="loginForm" onClick={setShowSignUp}>
          <a className="ml-2 text-white underline">Skapa konto</a>
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
