import React from 'react';

type Props = {
  className?: string,
  color?: string,
};

const ArrowLeft: React.FC<Props> = ({ className, color = "#191B16" }) => {
  color
  return (
    <svg className={className} width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 9.00012L0.999999 9.00012" stroke={color} strokeWidth="2" />
      <path d="M9.29214 1.22207L1.51396 9.00024L9.29214 16.7784" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default ArrowLeft;
