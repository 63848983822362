import React from 'react'
import Dialog from 'components/Dialog'

const NewsletterCompanySignup: React.FC<{}> = ({ }) => {
  return (
    <div className="bg-rose-100">
      <a id="newsletter" style={{ visibility: 'hidden' }}></a>
      <div className="container py-24 px-0">
        <div className="mx-auto max-w-[1400px]">
          <div className="flex flex-col items-center justify-center px-2">
            <h3 className="heading-h3 text-center mb-4">Let&apos;s stay in touch 🫶</h3>
            <p className="mb-4">
              Få LiveShopping-inspiration, branschnyheter och mer direkt i din inkorg!
            </p>
            <Dialog
              title=""
              button={<button className="btn-outline">Yes please 🙋‍♀️</button>}>
              <div className="flex justify-center w-full mt-20">
                  <iframe
                    src="/mailchimp_creators.html"
                    width="360"
                    height="381"
                    style={{ border: 0 }}>
                  </iframe>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsletterCompanySignup