import React from 'react';

type Props = {};

const Play: React.FC<Props> = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5169 6.32325C12.179 6.70901 12.179 7.66559 11.5169 8.05135L1.64625 13.8021C0.979595 14.1905 0.142853 13.7096 0.142853 12.938L0.142854 1.4366C0.142854 0.665048 0.979597 0.184146 1.64626 0.572545L11.5169 6.32325Z"
        fill="#191B16"
      />
    </svg>
  );
};

export default Play;
