
import axios from 'axios';
import Router from 'next/router';
import React, { useEffect, useState, useRef } from 'react'
import TextInput from '../components/TextInput'
import Dialog from 'components/Dialog';
import { useProfile } from 'context/UserContext';

interface ISettingsProps {
  name: string;
  template: string;
}

export const SettingsPage: React.FC<ISettingsProps> = (props) => {
  const { state, dispatch } = useProfile()
  const { user } = state
  const dialogRef = useRef<React.ElementRef<typeof Dialog>>(null)

  const [surname, setSurname] = useState<string>(user?.first_name ?? '');
  const [lastname, setLastname] = useState<string>(user?.last_name ?? '');
  const [emailAdr, setEmailAdr] = useState<string>(user?.email ?? '');
  const [subscribeNewsletter, setSubscribeNewsletter] = useState<boolean>(user?.newsletter ?? false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [editMsg, setEditMsg] = useState<string>('');


  useEffect(() => {
    // when reloading page the page renders before we get user context values
    const { user } = state
    setSurname(user?.first_name ?? '')
    setLastname(user?.last_name ?? '')
    setEmailAdr(user?.email ?? '')
    setSubscribeNewsletter(user?.newsletter ?? false)
  }, [state])


  // HANDLERS
  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEditMsg('')
    setErrorMsg('')
    // Get data from the form.
    try {
      const data = await axios.post('/api/auth/profile', {first_name: surname, last_name: lastname, email: emailAdr, newsletter: subscribeNewsletter});
      dispatch({ type: 'update_userdata', data: data?.data });
      setEditMsg('Ändringarna är nu sparade ✓')
    } catch (error: any) {
      console.log(error);
      setErrorMsg(error?.response?.data?.message || 'Något gick fel')
    }
    
  };

  const handleLogout = async () => {
    await axios.post('/api/auth/logout');
    dispatch({ type: 'remove_userdata' });
    window.location.href = '/';
  };

  return (
    <div className="container">
      <div className="bg-black bg-opacity-5">
        <div className="mx-auto max-w-2xl px-5 py-9 md:py-28">
          <form onSubmit={handleOnSubmit}>
            <h2 className="heading-h2 mb-4">Inställningar</h2>
            <div className="mb-4 grid place-items-end gap-2 sm:grid-cols-2">
              <TextInput
                id="surname"
                label="Ditt namn"
                type="text"
                placeholder="Förnamn"
                value={surname}
                set={setSurname}
              />
              <TextInput
                hideLabel
                id="lastname"
                label="Efternamn"
                type="text"
                placeholder="Efternamn"
                value={lastname}
                set={setLastname}
              />
            </div>
            <div className="">
              <TextInput
                id="email"
                label="Epost"
                type="text"
                placeholder="Ange en epost adress"
                value={emailAdr}
                set={setEmailAdr}
              />
            </div>
            <div className="flex items-end justify-center h-[40px]">
              { editMsg && <p className="text-[#36a380]">{editMsg}</p> }
              { errorMsg && <p className="text-red">{errorMsg}</p> }
            </div>
            <hr />
            <div className="flex items-center justify-between py-6">
              <button onClick={handleLogout} type="button" className="btn-outline">
                Logga ut
              </button>
              <button type="submit" className="btn-black">
                Spara ändringar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage