import React, { useCallback, useContext, useEffect, useRef } from 'react';
import Link from 'next/link';
import type { ShowList as IShowList } from 'types/ShowList.interface';
import Carousel from 'components/Carousel';
import ShowCard, { ShowCardPlace } from 'components/ShowCard';
import { useProfile } from "context/UserContext";

const ShowList: React.FC<IShowList> = ({ content, name }) => {
  const { state } = useProfile()
  const topNumsRef: any = useRef()

  const isToplist = name === "toplist"
  const isFavourites = name === "favourite-show-list"
  const isOriginalList = name === "showroom-originals"
  const isStartList = name === "start-show-list"
  const bg = isOriginalList ? 'bg-teal-100' : isToplist ? 'bg-rose-100' : ''
  let showsList = content?.shows ?? []

  useEffect(() => {
    const topNumsAnimate = () => {
      document.querySelectorAll('.top-numbers').forEach(el => el.classList.add('animate'))
    }
    const topListObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        topNumsAnimate()
        //topListObserver.disconnect()
      }
    })
    if (topNumsRef.current) topListObserver.observe(topNumsRef.current);
    return () => {
      topListObserver.disconnect()
    }
  }, [])

  if (isFavourites) {
    showsList = state?.favourites?.shows ?? []
  }

  if (!showsList?.length) return null

  const ShowAllBtn = () => {
    return ((!content?.more || (showsList?.length < 10)) ? null :
      <div className="flex w-full items-center justify-start p-6">
        <Link href={content?.more?.url ?? '/'}>
          <a className={`btn-white py-3 ${isToplist ? 'bg-white' : 'bg-[rgb(237,237,237)]'}`}>{content?.more?.label ?? 'Visa alla'}</a>
        </Link>
      </div>)
  }

  return (
    <div className={`${bg} ${isStartList ? 'sm:-mt-[10vw]' : ''}`}>
      <div className={`container lg:px-16`}>
        <div className={isOriginalList || isToplist ? 'pt-8 pb-6 mb-7 sm:pb-7 md:pt-14 md:pb-24 md:mb-20' : 'mb-7 sm:md-14 md:mb-20'}>
          <div className="mx-auto max-w-7xl">
            <Carousel title={content?.title ?? ''} isToplist={isToplist} isStartList={isStartList} showAllBtn={ShowAllBtn()} linkAll={content?.more?.url}>
              {showsList?.map((show, idx) => {
                return <div className={isToplist ? "relative w-full p-[6px] md:p-4" : "relative w-full p-[6px] md:p-3"} key={idx}>
                  {isToplist && (
                    <div className={`top-numbers top-number-${idx + 1}  btn-black absolute w-8 h-8 md:w-16 md:h-16 -top-2 -left-2 md:-top-3 md:-left-3 z-10 text-[16px] md:text-[32px]`}>
                      <p className="ml-[6px]">
                        {idx + 1}.
                      </p>
                    </div>
                  )}
                  <ShowCard place={ShowCardPlace.List} show={show} />
                </div>
              })}
            </Carousel>
            {isToplist && <span ref={topNumsRef}></span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShowList);
