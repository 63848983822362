import React, { useState } from 'react'
import Play from 'icons/Play'
import Dialog from './Dialog';
import ReactPlayer from 'react-player';
export type Tag = { name: string; value: string | number; color?: string };

type VimeoPlayBtnProps = {
  providerId: string;
}

const VimeoPlayBtn: React.FC<VimeoPlayBtnProps> = ({ providerId }) => {
  const [playing, setPlaying] = useState(true)
  
  return (
    <Dialog
      maxWidth='4xl'
      closeOutside
      button={
        <button
          aria-label="play show"
          className="btn-white flex h-[39px] w-[39px] items-center justify-center md:h-11 md:group-hover:w-auto bg-opacity-80
            static before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:h-full"
          >
          <span className="mt-[2px] ml-[2px]">
            <Play />
          </span>
          <span className="ml-2 hidden md:group-hover:inline-block ">Spela show</span>
        </button>
      }>
        <div className='bg-black h-[512px] flex-1 pointer-events-none'>
        <ReactPlayer
          height='100%'
          width='100%'
          playing={playing}
          controls={false}
          url={`https://vimeo.com/${providerId}`}
          onPlay={() => setPlaying(true) }
          onPause={() => setPlaying(false)}
        />
        </div>
    </Dialog>
  )
}

export default VimeoPlayBtn;
