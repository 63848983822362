import React, { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { Category } from 'types/Category.interface';

import ChevronDown from 'icons/ChevronDown';
import CheckBox from 'icons/CheckBox';

interface IProps {
  categories: Category[];
  setSelectedCategories: Function;
}

const MultiSelect: React.FC<IProps> = ({ categories = [], setSelectedCategories }) => {
  const [selected, setSelected] = useState<Category[]>([]);

  const isSelected = (value: any) => {
    return selected.find((el) => el === value) ? true : false;
  };

  const handleSelect = (value: any) => {
    if (!isSelected(value)) {
      const selectedUpdated: any = [...selected, categories.find((el) => el === value)];
      setSelected(selectedUpdated);
      setSelectedCategories(selectedUpdated);
    } else {
      handleDeselect(value);
    }
  };

  const handleDeselect = (value: any) => {
    const selectedUpdated = selected.filter((el) => el !== value);
    setSelected(selectedUpdated);
    setSelectedCategories(selectedUpdated);
  };

  return (
    <Listbox value={selected} onChange={(value) => handleSelect(value)}>
      {({ open }) => (
        <div className="relative mt-1">
          <Listbox.Button
            className={`btn-white z-20 flex justify-between ${
              open ? 'hover:drop-shadow-none rounded-b-none rounded-t-3xl' : 'hover:drop-shadow-lg'
            } relative w-full cursor-pointer py-2 pl-4 pr-2 text-left focus:outline-none focus-visible:border-teal-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300`}>
            <span className="block truncate">
              {selected.length < 1 ? 'Välj kategori' : selected.map((category) => category.name).join(', ')}
            </span>
            <div className={`origin-center transition duration-300 ease-in ${open ? 'rotate-180' : 'rotate-0'}`}>
              <ChevronDown />
            </div>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="sm:text-sm absolute top-10 z-10 max-h-60 w-full overflow-auto rounded-b-3xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {categories.map((category, categoryIdx) => (
                <Listbox.Option
                  key={categoryIdx}
                  className={({ active }) =>
                    `${active ? 'bg-teal-100 bg-opacity-20' : ''}
                    relative cursor-pointer py-2 px-4 font-normal text-black`
                  }
                  value={category}>
                  <div className="flex flex-row items-center">
                    <CheckBox checked={isSelected(category)} />
                    <p className="ml-2 block truncate">{category.name}</p>
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default MultiSelect;
