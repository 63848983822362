import React from 'react';

import BrandsGrid from './BrandsGrid';
import BrandsAll from './BrandsAll'
import BrandsCompact from './BrandsCompact'
import BrandHero from './BrandHero';
import BrandAbout from './BrandAbout';
import BrandsHero from './BrandsHero';
import About from './About';
import MainHero from './MainHero';
import BusinessNumbers from './BusinessNumbers';
import ShowList from './ShowList';
import ShowGrid from './ShowGrid';
import CategoryList from './CategoryList';
import CategoryHero from './CategoryHero';
import CategoryAbout from './CategoryAbout';
import Signup from './Signup';
import FeaturedShow from './FeaturedShow';
import ProductList from './ProductList';
import TwoColumn from './TwoColumn';
import FourColumn from './FourColumn';
import ShowHero from './ShowHero';
import Pricing from './Pricing';
import Testimonial from './Testimonial';
import ColorHero from './ColorHero';
import TextSection from './TextSection';
import ArticleList from './ArticleList';
import FaqList from './FaqList';
import RssFeed from './RssFeed';
import Settings from './Settings'
import VideoEmbed from './VideoEmbed'
import ImageSection from './ImageSection'
import NewsletterCompanySignup from './NewsletterCompanySignup'
import NewsletterCustomerSignup from './NewsletterCustomerSignup'
import SimpleVideo from './SimpleVideo';
import RawHTML from './RawHTML';

type Props = {
  sections?: any[];
};

const Sections = {
  About: About,
  BrandGrid: BrandsGrid,
  BrandsAll: BrandsAll,
  BrandsCompact: BrandsCompact,
  BrandHero: BrandHero,
  BrandAbout: BrandAbout,
  MainHero: MainHero,
  BusinessNumbers: BusinessNumbers,
  ShowList: ShowList,
  CategoryList: CategoryList,
  CategoryHero: CategoryHero,
  CategoryAbout: CategoryAbout,
  Signup: Signup,
  FeaturedShow: FeaturedShow,
  ProductList: ProductList,
  ShowHero: ShowHero,
  BrandsHero: BrandsHero,
  TwoColumn: TwoColumn,
  FourColumn: FourColumn,
  Testimonial: Testimonial,
  Pricing: Pricing,
  ColorHero: ColorHero,
  Text: TextSection,
  ArticleList: ArticleList,
  Faq: FaqList,
  RssFeed: RssFeed,
  Settings: Settings,
  VideoEmbed: VideoEmbed,
  ShowGrid: ShowGrid,
  Image: ImageSection,
  NewsletterCompanySignup: NewsletterCompanySignup,
  NewsletterCustomerSignup: NewsletterCustomerSignup,
  SimpleVideo: SimpleVideo,
  RawHTML: RawHTML,
};

const TemplateFactory: React.FC<Props> = ({ sections }) => {
  return (
    <>
      {sections?.map((section, idx) => {
        const template = section.template as keyof typeof Sections;
        const name = section.name
        if (typeof Sections[template] !== 'undefined' && name) {
          const SectionBlock = Sections[template];
          return <SectionBlock {...section} key={idx} />;
        } else if (process.env.NEXT_PUBLIC_RELEASE_STAGE === 'staging' || process.env.NEXT_PUBLIC_RELEASE_STAGE === 'develop') {
          return (
            <div className="container mb-8 sm:mb-14 md:mb-20" key={idx}>
              <div className="my-3 bg-rose-300 p-4 text-center">
                {
                  !name
                    ? <p>The view {template} has no data or name.</p>
                    : <p>The view {template} has not been created yet.</p>
                }
              </div>
            </div>
          )
        }
      })}
    </>
  );
};

export default TemplateFactory;
