import React from 'react';

type Props = {
  color?: string,
  size?: string,
  center?: boolean,
};

const Spinner: React.FC<Props> = ({ color = "#424242", size = "23", center = false }) => {
  return (
    <svg className={`spinner ${center ? "block m-auto" : ""}`} width={size} height={size} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle className="spinner" cx="50" cy="50" r="45" stroke={color} />
    </svg>
  );
};

export default Spinner;
