
import React from 'react';

type Props = {};

const Play: React.FC<Props> = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="12" rx="1" fill="black"/>
  </svg>
)

export default Play;
