import React from 'react';

type Props = {
  size?: string;
};
const ChevronRight: React.FC<Props> = ({ size = '1.5rem' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
    </svg>
  );
};

export default ChevronRight;
