import React, { Fragment, useState } from 'react';
import { Dialog as DialogUi, Transition } from '@headlessui/react';
import { useProfile } from 'context/UserContext';
import X from 'icons/X';

type Props = {
  button: React.ReactElement;
  title?: string;
  maxWidth?: 'lg' | 'xl' | '3xl' | '4xl';
  isOpen?: boolean;
  actions?: React.ReactElement;
  onClose?: () => void;
  children: JSX.Element;
  closeOutside?: boolean;
};

export type Handle = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Dialog({ title, button, maxWidth, isOpen = false, onClose, actions, children, closeOutside = false }: Props, ref: any) {
  const [open, setOpen] = useState(isOpen);
  const { state } = useProfile()
  const { user } = state

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  React.useImperativeHandle(
    ref,
    () => ({
      setOpen,
    }),
    []
  );
  return (
    <>
      {React.cloneElement(button, {
        onClick: (event: any) => {
          if (button.props.onClick && user) {
            button.props.onClick(event);
          } else {
            handleOpen(event);
          }
        },
      })}

      <Transition appear show={open} as={Fragment}>
        <DialogUi as="div" className="fixed inset-0 z-20 p-4 overflow-y-auto" onClose={handleClose}>
          <div className="min-h-screen px-0 md:px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <DialogUi.Overlay className="fixed inset-0 bg-black/60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div
                className={`relative inline-block w-full overflow-hidden transform max-w-[630px] rounded-2xl bg-white align-middle shadow-xl transition-all
                ${maxWidth === 'lg' ? 'max-w-lg' : ''}
                ${maxWidth === 'xl' ? 'max-w-xl' : ''}
                ${maxWidth === '3xl' ? 'max-w-3xl' : ''}
                ${maxWidth === '4xl' ? 'max-w-4xl' : ''}`}>
                <button className={ closeOutside ? "fixed z-10 top-4 right-4 btn-white bg-opacity-80 rounded-full p-4" : "absolute top-8 right-8"} onClick={() => setOpen(false)}>
                  <X />
                </button>
                {!!title && (
                  <DialogUi.Title as="h2" className="heading-h2 p-10 pb-0 text-left mb-7 ml-0 max-w-lg">
                    {title}
                  </DialogUi.Title>
                )}
                {React.cloneElement(children, { setOpen })}
                {actions}
              </div>

            </Transition.Child>

          </div>
        </DialogUi>
      </Transition>
    </>
  );
}

export default React.forwardRef<Handle, Props>(Dialog);
