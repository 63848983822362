import React from 'react'
import Dialog from 'components/Dialog'

const NewsletterCustomerSignup: React.FC<{}> = ({ }) => {
    return (
        <div className="bg-rose-100">
            <a id="newsletter" style={{ visibility: 'hidden' }}></a>
            <div className="container py-24 px-0">
                <div className="mx-auto max-w-[1400px]">
                    <div className="flex flex-col items-center justify-center px-2">
                        <h3 className="heading-h3 text-center mb-4">Let&apos;s stay in touch 🫶</h3>
                        <p className="mb-4 text-center">
                            Få exklusiva erbjudanden och håll dig uppdaterad om de senaste
                            trenderna med Showroom Magazine!
                        </p>
                        <Dialog
                            title=""
                            button={<button className="btn-outline">Yes please 🙋‍♀️</button>}>
                            <div className="flex justify-center w-full mt-20">
                                <iframe
                                    src="/rule.html"
                                    width="500"
                                    height="381"
                                    style={{ border: 0 }}></iframe>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsletterCustomerSignup