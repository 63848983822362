import React, { useState } from 'react';
import { VideoEmbed as IVideoEmbed } from 'types/VideoEmbed.interface';
import ReactPlayer from 'react-player/lazy'
import Play from 'icons/Play';
import Stop from 'icons/Stop';

const VideoEmbed: React.FC<IVideoEmbed> = ({ title, button_text, video_platform, video_id }) => {
  const [playing, setPlaying] = useState(false)
  const [activeHover, setActiveHover] = useState(false)
  const hostURL = video_platform === "youtube" ? "https://www.youtube.com/embed/" : "https://vimeo.com/" ;

  const onMouseLeave = () => { setActiveHover(true) }

  return <div className="container mb-8 sm:mb-14 md:mb-20">
    <div onMouseLeave={onMouseLeave} className=" group relative flex justify-center items-center aspect-[16/9] max-w-[939px] mx-auto rounded-3xl overflow-hidden">
      <ReactPlayer
        height='100%'
        width='100%'
        playing={playing}
        controls={false}
        loop={true}
        muted={true}
        url={hostURL + video_id}
        onPlay={() => { setActiveHover(false); setPlaying(true) }}
        onPause={() => setPlaying(false)}
      />
      <div className={`absolute ${playing ? 'hidden' : ''} ${activeHover ? 'group-hover:block' : ''}`}>
        <h2 className=" heading-h2 text-white mx-4 text-center">{title}</h2>
        <button onClick={() => setPlaying(!playing)} className={`btn-white mx-auto mt-6 flex justify-center ${!playing ? 'px-4' : ''}`}>
          {
            playing
              ? <span className="mr-2"><Stop /></span>
              : <span className="my-2 ml-1"><Play/></span>
          }
          {playing ? 'Stoppa video' : button_text}
        </button>
      </div>
    </div>
  </div>
};

export default React.memo(VideoEmbed);
