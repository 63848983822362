import React, { useState } from 'react';
import { Transition } from '@headlessui/react'
import parse from 'html-react-parser';

type Props = {
  title: string;
  content: string;
  open?: boolean;
};

const Collapse: React.FC<Props> = ({ title, content, open = false }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className="cursor-pointer border-b-[1px] border-b-black pb-2" onClick={() => setIsOpen((prev) => !prev)}>
      <div className="my-4 md:my-9 flex items-center justify-between ">
        <h4 className="heading-h4 max-w-[80%]">{title}</h4>
        {
          isOpen
            ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 12H24" stroke="#191B16" strokeWidth="2"/>
                {/* <path d="M12 0L12 24" stroke="#191B16" strokeWidth="2"/> */}
              </svg>
            : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 12H24" stroke="#191B16" strokeWidth="2"/>
              <path d="M12 0L12 24" stroke="#191B16" strokeWidth="2"/>
            </svg>
        }
      </div>
      <Transition
        show={isOpen}
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <div className={`mb-6 font-body text-base`}>{parse(content)}</div>
      </Transition>
    </div>
  );
};

export default React.memo(Collapse);
